<template>
  <div class="content-body">
    <div class="worker_serch_warp">
      <div class="row d-flex justify-content-between">
        <div class="col-md-4 pr-2 mx-2">
          <h2 class="content-title">
            <a @click="$router.go(-1)" style="cursor: pointer"
              ><i class="fas fa-angle-left mr-2"></i
              ><span v-if="!this.id">Add Loan Application</span>
              <span v-else>Edit Loan Application</span>
            </a>
          </h2>
        </div>
      </div>
    </div>
    <div class="component-section no-code">
      <div class="row-sm wb-row-gap">
        <Form
          @submit="save"
          class="columns column is-multiline is-12"
          data-vv-scope="formAddLoanType"
          ref="addEditLoanApplication"
        >
          <div class="col-lg-12">
            <div class="card rounded-5">
              <div class="card-body p-3 p-lg-3">
                <h4 style="border-bottom: 1px solid #cccccc; line-height: 2">
                  Basic Info
                </h4>
                <div class="row">
                  <div class="col-lg-4 col-sm-12 pt-2">
                    <label
                      >Mobile Number <span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                      <Field
                        name="mobile_number"
                        class="form-control"
                        id="mobile_number"
                        rules="required:mobile number |phone|numeric"
                        :validateOnInput="true"
                        v-model="loanApplication.mobile_number"
                        type="text"
                        autoComplete="off"
                        placeholder="Mobile Number"
                        autocapitalize="false"
                      />
                      <div
                        class="input-group-append"
                        v-show="has_search_api_call === true"
                      >
                        <div class="input-group-text">
                          <div
                            class="spinner-border mobile_number_spinner text-primary"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ErrorMessage name="mobile_number" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-3 pt-2">
                    <label>
                      Name
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="name"
                      id="name"
                      rules="required:name"
                      class="form-control"
                      v-model="loanApplication.name"
                      type="text"
                      autoComplete="off"
                      v-bind:placeholder="'Name'"
                    />
                    <ErrorMessage name="name" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label
                      >Source
                      <span class="text-danger">*</span>
                    </label>
                    <Field
                      v-slot="{ field }"
                      name="source"
                      rules="required:source,true"
                      :validateOnInput="true"
                      v-model="loanApplication.source"
                    >
                      <Multiselect
                        v-bind="field"
                        ref="source_multiselect"
                        mode="single"
                        trackBy="value"
                        label="title"
                        valueProp="value"
                        placeholder="Select source"
                        class="form-control"
                        v-model="loanApplication.source"
                        :options="sourceList"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="level" class="validation-msg" />
                  </div>
                  <div
                    class="col-lg-4 mb-4"
                    v-show="loanApplication.source === 'WITH_DOCUMENT'"
                  >
                    <label>Email </label>
                    <Field
                      name="email"
                      class="form-control"
                      rules="email"
                      :validateOnInput="true"
                      v-model="loanApplication.email"
                      type="text"
                      autoComplete="off"
                      placeholder="Email"
                      autocapitalize="false"
                    />
                    <ErrorMessage name="email" class="validation-msg" />
                  </div>
                  <div
                    class="col-lg-4 mb-4"
                    v-show="loanApplication.source === 'WITH_DOCUMENT'"
                  >
                    <label>Alt Mobile Number 1 </label>
                    <Field
                      name="alt_mobile_number_1"
                      class="form-control"
                      rules="phone|numeric"
                      :validateOnInput="true"
                      v-model="loanApplication.alt_mobile_number_1"
                      type="text"
                      autoComplete="off"
                      placeholder="Alt Mobile Number 1 "
                      autocapitalize="false"
                    />
                    <ErrorMessage
                      name="alt_mobile_number_1"
                      class="validation-msg"
                    />
                  </div>

                  <div
                    class="col-lg-4 mb-4"
                    v-show="loanApplication.source === 'WITH_DOCUMENT'"
                  >
                    <label>Alt Mobile Number 2</label>
                    <Field
                      name="alt_mobile_number_2"
                      class="form-control"
                      rules="phone|numeric"
                      :validateOnInput="true"
                      v-model="loanApplication.alt_mobile_number_2"
                      type="text"
                      autoComplete="off"
                      placeholder="Alt Mobile Number 2"
                      autocapitalize="false"
                    />
                    <ErrorMessage
                      name="alt_mobile_number_2"
                      class="validation-msg"
                    />
                  </div>
                  <div
                    class="col-sm-4 mb-4 pt-2"
                    v-show="loanApplication.source === 'WITH_DOCUMENT'"
                  >
                    <label
                      >Date of Birth<span class="text-danger">*</span></label
                    >
                    <Field
                      name="date_of_birth"
                      class="form-control"
                      v-slot="{ date }"
                      :rules="
                        loanApplication.source === 'WITH_DOCUMENT'
                          ? 'required:date of birth,true|ageValidation'
                          : ''
                      "
                      id="date_of_birth"
                      v-model="loanApplication.date_of_birth"
                    >
                      <VueDatePicker
                        v-bind="date"
                        placeholder="Select Date"
                        style="height: 46px !important"
                        v-model="loanApplication.date_of_birth"
                        :type="boolean"
                        :format="'dd/MM/yyyy'"
                        :default="true"
                        :enable-time-picker="false"
                        :max-date="Date.now()"
                      >
                      </VueDatePicker>
                    </Field>
                    <ErrorMessage name="date_of_birth" class="validation-msg" />
                  </div>
                  <div
                    class="col-lg-4 mb-3 pt-2"
                    v-show="loanApplication.source === 'WITH_DOCUMENT'"
                  >
                    <label>
                      Occupation
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      v-slot="{ field }"
                      name="occupation"
                      :rules="
                        loanApplication.source === 'WITH_DOCUMENT'
                          ? 'required:occupation,true'
                          : ''
                      "
                      :validateOnInput="true"
                      v-model="occupation"
                    >
                      <Multiselect
                        v-bind="field"
                        rules="required:occupation,true"
                        ref="occupation_multiselect"
                        mode="single"
                        trackBy="title"
                        label="title"
                        valueProp="value"
                        placeholder="Select occupation"
                        class="form-control"
                        searchable="true"
                        v-model="loanApplication.occupation"
                        :options="occupations"
                      />
                    </Field>
                    <ErrorMessage name="occupation" class="validation-msg" />
                  </div>
                  <div
                    class="col-lg-4 mb-4"
                    v-show="loanApplication.source === 'WITH_DOCUMENT'"
                  >
                    <label>Profession</label>
                    <Field
                      name="profession"
                      class="form-control"
                      :validateOnInput="true"
                      v-model="loanApplication.profession"
                      type="text"
                      autoComplete="off"
                      placeholder="Profession"
                      autocapitalize="false"
                    />
                  
                  </div>
                  <div
                    class="col-lg-4 mb-3 pt-2"
                    v-show="loanApplication.source === 'WITH_DOCUMENT'"
                  >
                    <label> Gender<span class="text-danger">*</span> </label>
                    <div class="">
                      <span class="mr-3">
                        <input
                          class=""
                          name="gender"
                          v-model="loanApplication.gender"
                          :checked="
                            loanApplication.gender == 'MALE' ? true : false
                          "
                          type="radio"
                          value="MALE"
                          id="male"
                          style="width: 20px; height: auto"
                        />
                        <label class="form-check-label" for="male">
                          Male
                        </label>
                      </span>
                      <span>
                        <input
                          class=""
                          v-model="loanApplication.gender"
                          :checked="
                            loanApplication.gender == 'FEMALE' ? true : false
                          "
                          type="radio"
                          name="gender"
                          value="FEMALE"
                          id="female"
                          style="width: 20px; height: auto"
                        />
                        <label class="form-check-label" for="female">
                          Female
                        </label>
                      </span>
                    </div>
                    <ErrorMessage name="gender" class="validation-msg" />
                  </div>
                </div>
                <h4
                  style="border-bottom: 1px solid #cccccc; line-height: 2"
                  v-show="loanApplication.source === 'WITH_DOCUMENT'"
                >
                  Address
                </h4>
                <div
                  class="row"
                  v-show="loanApplication.source === 'WITH_DOCUMENT'"
                >
                  <div class="col-lg-4 mb-3 pt-2">
                    <label> Address Type </label>
                    <div class="">
                      <span class="mr-3">
                        <input
                          class=""
                          v-model="loanApplication.has_owned"
                          name="home_address_type"
                          :checked="
                            loanApplication?.has_owned == 0 ? true : false
                          "
                          type="radio"
                          value="0"
                          id="Rented"
                          style="width: 20px; height: auto"
                        />
                        <label class="form-check-label" for="Rented">
                          Rented
                        </label>
                      </span>
                      <span>
                        <input
                          class=""
                          v-model="loanApplication.has_owned"
                          name="home_address_type"
                          :checked="
                            loanApplication.has_owned == 1 ? true : false
                          "
                          type="radio"
                          value="1"
                          id="Owned"
                          style="width: 20px; height: auto"
                        />
                        <label class="form-check-label" for="Owned">
                          Owned
                        </label>
                      </span>
                    </div>
                    <ErrorMessage
                      name="home_address_type"
                      class="validation-msg"
                    />
                  </div>

                  <div class="col-lg-4 mb-3 pt-2">
                    <label>
                      Address
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="home_address"
                      id="home_address"
                      :rules="
                        loanApplication.source === 'WITH_DOCUMENT'
                          ? 'required:address'
                          : ''
                      "
                      class="form-control"
                      v-model="loanApplication.address"
                      type="text"
                      autoComplete="off"
                      v-bind:placeholder="'Address'"
                    />
                    <ErrorMessage name="home_address" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-3 pt-2">
                    <label>
                      City
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="home_city"
                      id="home_city"
                      :rules="
                        loanApplication.source === 'WITH_DOCUMENT'
                          ? 'required:city'
                          : ''
                      "
                      class="form-control"
                      v-model="loanApplication.city"
                      type="text"
                      autoComplete="off"
                      v-bind:placeholder="'City'"
                    />
                    <ErrorMessage name="home_city" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-3 pt-2">
                    <label>
                      State
                      <span class="text-danger">*</span>
                    </label>
                    <Field
                      v-slot="{ field }"
                      name="home_state"
                      :rules="
                        loanApplication.source === 'WITH_DOCUMENT'
                          ? 'required:state,true'
                          : ''
                      "
                      :validateOnInput="true"
                      v-model="loanApplication.state_id"
                    >
                      <Multiselect
                        v-bind="field"
                        rules="required:state,true"
                        ref="home_state_multiselect"
                        mode="single"
                        trackBy="name"
                        label="name"
                        valueProp="id"
                        placeholder="Select State"
                        class="form-control"
                        v-model="loanApplication.state_id"
                        searchable="true"
                        :options="stateList"
                      />
                    </Field>
                    <ErrorMessage name="home_state" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-3 pt-2">
                    <label>
                      Pincode
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="home_pincode"
                      id="home_pincode"
                      
                      :rules="
                        loanApplication.source === 'WITH_DOCUMENT'
                          ? 'required:pincode|pincode'
                          : ''
                      "
                      class="form-control"
                      v-model="loanApplication.pincode"
                      type="text"
                      autoComplete="off"
                      v-bind:placeholder="'Pincode'"
                    />
                    <ErrorMessage name="home_pincode" class="validation-msg" />
                  </div>
                </div>
                <h4 style="border-bottom: 1px solid #cccccc; line-height: 2">
                  Loan Details
                </h4>
                <div class="row">
                  <div class="col-lg-4 mb-4" v-if="hasFullAccess">
                    <label> Branch <span class="text-danger">*</span> </label>
                    <Field
                      v-slot="{ field }"
                      name="branch_list"
                      rules="required:branch,true"
                      :validateOnInput="true"
                      v-model="loanApplication.branch_id"
                    >
                      <Multiselect
                        v-bind="field"
                        ref="branch_multiselect"
                        mode="single"
                        trackBy="name"
                        rules="required"
                        label="name"
                        valueProp="branch_id"
                        placeholder="Select Branch"
                        class="form-control"
                        v-model="loanApplication.branch_id"
                        :options="branchList"
                        :close-on-select="true"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="branch_list" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label
                      >Loan Type
                      <span class="text-danger">*</span>
                    </label>
                    <Field
                      v-slot="{ field }"
                      name="level"
                      rules="required:level,true"
                      :validateOnInput="true"
                      v-model="loanApplication.loan_type_id"
                    >
                      <Multiselect
                        v-bind="field"
                        ref="level_multiselect"
                        mode="single"
                        @search-change="onCheckUserTire"
                        trackBy="level"
                        label="title"
                        valueProp="id"
                        placeholder="Select Loan Type"
                        class="form-control"
                        v-model="loanApplication.loan_type_id"
                        :options="loanType"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="level" class="validation-msg" />
                  </div>

                  <div class="col-lg-4 mb-4">
                    <label
                      >Loan Amount <span class="text-danger">*</span>
                    </label>
                    <Field
                      name="loan_amount"
                      class="form-control"
                      rules="required:loan amount|numeric|multiply"
                      :validateOnInput="true"
                      v-model="loanApplication.loan_amount"
                      type="text"
                      autoComplete="off"
                      placeholder="Loan Amount"
                      autocapitalize="false"
                    />
                    <ErrorMessage name="loan_amount" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label>Apply Date<span class="text-danger">*</span></label>
                    <Field
                      name="apply_date"
                      class="form-control"
                      v-slot="{ date }"
                      rules="required:apply date,true"
                      id="apply_date"
                      v-model="loanApplication.apply_date"
                    >
                      <VueDatePicker
                        v-bind="date"
                        placeholder="Select Apply Date "
                        style="height: 46px !important"
                        v-model="loanApplication.apply_date"
                        :type="boolean"
                        :format="'dd/MM/yyyy'"
                        :default="true"
                        :enable-time-picker="false"
                      >
                      </VueDatePicker>
                    </Field>
                    <ErrorMessage name="apply_date" class="validation-msg" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-3">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
                  @click="$router.go(-1)"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  id="save-btn"
                  class="btn btn-brand-01 wb-save-btn"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Form, Field, ErrorMessage } from "vee-validate";
import VueDatePicker from "@vuepic/vue-datepicker";
4;
import moment from "moment";
export default {
  name: "AddEditLoanApplication",
  components: {
    Multiselect,
    Form,
    Field,
    ErrorMessage,
    VueDatePicker,
  },
  data() {
    return {
      has_search_api_call: false,
      lead_id: this.$route.params.lead_id,
      id: this.$route.params.id,
      loanApplication: {
        name: "",
        email: "",
        gender: "MALE",
        mobile_number: "",
        date_of_birth: "",
        occupation: "",
        alt_mobile_number_1: "",
        alt_mobile_number_2: "",
        loan_type_id: "",
        emi_interest: "",
        branch_id: "",
        loan_amount: 0,
        source: "",
        apply_date: moment(new Date()).format("Y-MM-DD"),
        has_owned: "0",
        address: "",
        city: "",
        pincode: "",
        state_id: "",
        type: "HOME",
        address_category: "PERMANENT",
      },
      branch_ids: [],
      user_type: null,
      loanType: [],
      statusOption: [
        {
          title: "Active",
          value: "ACTIVE",
        },
        {
          title: "Inactive",
          value: "INACTIVE",
        },
      ],
      sourceList: [
        {
          title: "With Document",
          value: "WITH_DOCUMENT",
        },
        {
          title: "Without Document",
          value: "WITHOUT_DOCUMENT",
        },
      ],
      occupations: [],
      stateList: [],
    };
  },
  watch: {
    "loanApplication.mobile_number": function (newVal) {
      if (newVal) {
        if (newVal.length >= 10) {
          this.checkMobileNumber();
        } else {
          this.clearLoanApplicationPreFieldData();
        }
      }
    },
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getBranches;
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    },
  },
  mounted() {
    this.onGetOccupation();
    this.onGetStateList();
    this.user_type = localStorage.getItem("user_type");
    if (this.user_type === "DOCUMENT_VERIFY_USER") {
      this.$router.go(-1);
    }
    if (this.id) {
      this.getUserInfo();
    } else {
      if (this.lead_id) {
        this.onCheckUserTire();
        this.getLeadInfo();
      }
    }
  },
  methods: {
    onGetStateList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/states",
          _body: null,
          _buttonId: "",
          _hide_loader: false,
        })
        .then((res) => {
          this.stateList = res.list;
        })
        .catch(() => {});
    },
    onGetOccupation() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/occupations",
          _body: null,
          _buttonId: "",
          _hide_loader: false,
        })
        .then((res) => {
          this.occupations = res.list;
        })
        .catch(() => {});
    },
    getLeadInfo() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "lead/" + this.lead_id,
        })
        .then((res) => {
          this.loanApplication.name = res.info?.name;
          this.loanApplication.email = res.info?.email;
          this.loanApplication.mobile_number = res.info?.mobile_number;
          this.loanApplication.loan_amount = res.info?.loan_amount;
          if (res.info.branch) {
            this.loanApplication.branch_id = res?.info?.branch?.id;
          }

          if (res.info.loan_type) {
            this.loanApplication.loan_type_id = res?.info?.loan_type?.id;
          }
        })
        .catch(() => {});
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    onCheckUserTire() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/loan-type",
          _body: {
            email: this.loanApplication.email,
            mobile_number: this.loanApplication.mobile_number,
          },
        })
        .then((res) => {
          // this.loanType = res.list;

          for (var i = 0; i < res.list.length; i++) {
            var obj = {
              title:
                res.list[i].title +
                " (" +
                this.$helperService.getTitleCase(res.list[i].type) +
                ")" +
                " - " +
                this.$helperService.getTitleCase(res.list[i].level),
              id: res.list[i].id,
            };
            this.loanType.push(obj);
          }
          // if (this.lead_id) {
          //     this.getLeadInfo();
          // }
        })
        .catch(() => {});
    },
    getUserInfo() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "loan/" + this.id,
        })
        .then((res) => {
          this.loanApplication.name = res?.info?.applicant_name;
          this.loanApplication.email = res?.info?.email;
          this.loanApplication.mobile_number = res?.info?.mobile_number;
          this.loanApplication.source = res?.info?.source;
          if (res.info.branch) {
            this.loanApplication.branch_id = res?.info?.branch?.id;
          }
          this.onCheckUserTire();
          if (res.info.loan_type) {
            this.loanApplication.loan_type_id = res?.info?.loan_type?.id;
          }
          this.loanApplication.loan_amount = res?.info?.loan_amount;
        })
        .catch(() => {});
    },
    save() {
      console.log(this.loanApplication, "this.loanApplication");

      var method = "POST";
      var action = "/loan";
      if (this.id) {
        method = "PUT";
        action = "loan/" + this.id;
      }
      this.loanApplication.lead_id = this.lead_id;
      this.loanApplication.apply_date = moment(
        this.loanApplication.apply_date
      ).format("Y-MM-DD");

      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: this.loanApplication,
          _buttonId: "save-btn",
          _hide_loader: true,
        })
        .then((res) => {
          if (res?.status == "PENDING_FOR_VERIFICATION") {
            localStorage.setItem("loan_application_active_tab", res?.status);
            this.$router.push("/loan_applications");
          }
          this.$toast.success(res.message, { position: "top-right" });
          this.onClearForm("addEditLoanApplication");
          this.$route.go(-1);
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    checkMobileNumber() {
      this.has_search_api_call = true;
      this.$api
        .webRequest({
          _method: "POST",
          _action: "search/mobile_number",
          _body: { mobile_number: this.loanApplication.mobile_number },
          _hide_loader: true,
        })
        .then((res) => {
          this.onCheckUserTire();
          console.log("the response is :", res);
          this.has_search_api_call = false;
          this.loanApplication.name = res?.info?.name;
          this.loanApplication.email =
            res?.info?.email != null ? res?.info?.email : "";
          this.loanApplication.gender =
            res?.info?.info?.gender != null ? res?.info?.info?.gender : null;
          this.loanApplication.date_of_birth =
            res?.info?.info?.date_of_birth != null
              ? res?.info?.info?.date_of_birth
              : "";
          this.loanApplication.occupation =
            res?.info?.info?.occupation != null
              ? res?.info?.info?.occupation?.value
              : "";

          this.loanApplication.address =
            res?.info?.info?.address != null ? res?.info?.info?.address : "";
          this.loanApplication.city =
            res?.info?.info?.city != null ? res?.info?.info?.city : "";
          this.loanApplication.state_id =
            res?.info?.info?.state != null ? res?.info?.info?.state_id : null;
          this.loanApplication.pincode =
            res?.info?.info?.pincode != null ? res?.info?.info?.pincode : "";

          this.$toast.success(res.message, { position: "top-right" });
        })
        .catch((e) => {
          this.clearLoanApplicationPreFieldData();
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    clearLoanApplicationPreFieldData() {
      this.has_search_api_call = false;
      this.loanApplication.name = "";
      this.loanApplication.email = "";
      this.loanApplication.gender = "";
      this.loanApplication.date_of_birth = "";
      this.loanApplication.occupation = "";
      this.loanApplication.address = "";
      this.loanApplication.city = "";
      this.loanApplication.state_id = null;
      this.loanApplication.pincode = "";
    },
  },
};
</script>

<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
