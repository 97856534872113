<template>
  <div>
    <div class="content-body">
      <div class="component-section no-code">
        <div class="row">
          <div class="container-fluid">
            <div class="worker_serch_warp">
              <div class="row">
                <div class="col-lg-2 mb-2">
                  <div class="search-form position-relative">
                    <input
                      type="text"
                      class="form-control"
                      v-model="filterObj.keyword"
                       v-on:blur="getList(1)"
                      placeholder="Search"
                    />
                    <div class="input-group-prepend position-absolute">
                      <button class="" type="button" id="button-addon1">
                        <img src="/static/img/search.svg" width="15" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 mb-2" v-if="hasFullAccess">
                  <div
                    class="add_btn_wrapper d-flex flex-row justify-content-end"
                  >
                    <Multiselect
                      ref="branch_id_multiselect"
                      name="branch_id"
                      mode="single"
                      trackBy="name"
                      label="name"
                      valueProp="branch_id"
                      placeholder="Select Branch"
                      class="form-control"
                      v-model="filterObj.branch_ids"
                      :options="branchList"
                      searchable="true"
                    />
                  </div>
                </div>
                <div class="col-lg-3 mb-2">
                  <a @click="getList(1)" class="btn btn-brand-01 mr-2"
                    >Search
                  </a>
                  <a @click="reset()" class="btn btn-brand-03 mr-2">Reset </a>
                </div>
                <!-- <div class="col-lg-5 mb-2 text-right">
                  <a
                    @click="addIncenTive()"
                    class="btn btn-brand-02"
                    v-if="access.can_create == 1"
                    >+ Add
                  </a>
                </div> -->
              </div>
            </div>
            <div class="card rounded-5">
              <div class="card-body p-3 p-lg-3">
                <div class="row">
                  <div
                    class="col-lg-12 d-flex flex-row justify-content-end pb-3"
                  >
                    <button
                      v-if="access.can_print == 1"
                      @click="onExportReport()"
                      class="btn btn-brand-01 mr-2"
                      id="cibil_report"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                      Processing Fee Export
                    </button>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-primary mg-b-0">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'loan_applications.status')"
                        >
                          Branch Name
                        </th>
                        <th scope="col">Application NO</th>
                        <th
                          scope="col"
                          class="sorting"
                          @click="
                            sorting($event, 'loan_applications.sanction_amount')
                          "
                        >
                          Loan Amount
                        </th>
                        <th
                          scope="col"
                          class="sorting"
                          @click="
                            sorting($event, 'loan_applications.processing_fee')
                          "
                        >
                        Processing Fee
                        </th>
                        <th
                          scope="col"
                          class="sorting"
                          @click="sorting($event, 'loan_applications.gst')"
                        >
                          GST
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loader">
                        <td colspan="8" class="text-center">
                          <div
                            class="spinner-border spinner-color"
                            role="status"
                          ></div>
                        </td>
                      </tr>
                      <tr v-for="item in list" :key="item.id">
                        <td>{{ item?.branch_name }}</td>
                        <td>{{ item?.application_id }}</td>
                        <td>
                          {{
                            $helperService.getFormattedCurrency(
                              item?.sanction_amount
                            )
                          }}
                        </td>
                        <td>
                          {{
                            $helperService.getFormattedCurrency(
                              item?.processing_fee
                            )
                          }}
                        </td>
                        <td>
                          {{
                            $helperService.getFormattedCurrency(
                              item?.gst
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ErrorComponent @retry="getList(1)" ref="errorComponent" />
                </div>
                <div class="row">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="rolePagination"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ConfirmationModal @remove="onDeleteIncenTive" ref="deleteIncenTive" />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
// import moment from "moment";
import ErrorComponent from "@/components/ErrorComponent";
// import VueDatePicker from '@vuepic/vue-datepicker';
// import "@vueform/multiselect/themes/default.css"
import Multiselect from "@vueform/multiselect";
import $ from "jquery";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
export default {
  name: "FeeEarning",
  components: {
    Pagination,
    ErrorComponent,
    ConfirmationModal,
    // VueDatePicker,
    Multiselect,
  },

  data() {
    return {
      loader: false,
      currentPage: 1,
      totalPage: 0,
      itemsPerPage: 10,
      list: [],
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      filterObj: {
        keyword: "",
        branch_ids: "",
        offset: 1,
        sort_by: "loan_applications.created_at",
        sort_order: "desc",
        row_per_page: 10,
        status: "ALL",
      },
      delete_id: "",
      date: "",
      statusOption: [
        {
          title: "Office",
          value: "OFFICE",
        },
        {
          title: "Employee",
          value: "EMPLOYEE",
        },
      ],
    };
  },
  mounted() {
    this.$storeService.commit("setTitle", "Processing Fee Report");
    this.getList(1);
  },
  computed: {
    branchList() {
      return this.$storeService.getters.getAllBranches;
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    },
  },
  methods: {
    reset() {
      this.filterObj = {
        keyword: "",
        type: "",
        list_type: "TDS",
        offset: 1,
        sort_by: "created_at",
        sort_order: "desc",
        row_per_page: 10,
        status: "ALL",
        branch_ids: "",
      };
      this.date = "";
      this.getList(1);
    },

    pageChange(page) {
      this.getList(page);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.rolePagination.itemsPerPage;
      this.getList(1);
    },

    retry() {
      this.getList(1);
    },
    getList(page, sortBy, orderBy) {
      this.filterObj.offset = page - 1;
      this.currentPage = page;
      this.filterObj.row_per_page = this.itemsPerPage;

      if (sortBy) {
        this.filterObj.sort_by = sortBy;
      }
      if (orderBy) {
        this.filterObj.sort_order = orderBy;
      }

      // if (this.date) {
      //   this.filterObj.from_date = moment(String(this.date[0])).format(
      //     "YYYY-MM-DD"
      //   );
      //   this.filterObj.to_date = moment(String(this.date[1])).format(
      //     "YYYY-MM-DD"
      //   );
      // } else {
      //   this.filterObj.from_date = "";
      //   this.filterObj.to_date = "";
      // }

      this.list = [];
      this.loader = true;
      var method = "GET";
      this.$api
        .webRequest({
          _method: method,
          _action: "get-processing-fee",
          _body: this.filterObj,
        })
        .then((res) => {
          this.loader = false;

          console.log(res, "Response value");
          this.list = res.list;
          if (page == 1) {
            if (res.count != null && res.count > 0) {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(res.count);
              }
            } else {
              if (this.$refs.rolePagination) {
                this.$refs.rolePagination.setTotalCount(0);
              }
            }
          }
          if (!res.list || res.list.length <= 0) {
            if (this.$refs.errorComponent) {
              this.$refs.errorComponent.setTitle(
                "Sorry, There is no record found.",
                true
              );
            }
          } else {
            if (this.$refs.errorComponent) {
              if (this.$refs.errorComponent.isShowMessage()) {
                this.$refs.errorComponent.setTitle();
              }
            }
          }
          if (this.$refs.rolePagination) {
            this.$refs.rolePagination.currentPage = this.currentPage;
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setTitle();
          }
        });
    },
    sorting(e, sortBy) {
      var orderBy = "ASC";

      var className = "sorting_asc";
      var classes = e.target.className;
      if (classes.includes("sorting_asc")) {
        className = "sorting_desc";
      }

      $(".sorting_asc").removeClass("sorting_asc");
      $(".sorting_desc").removeClass("sorting_desc");

      if (className == "sorting_asc") {
        $(e.target).addClass("sorting_asc").removeClass("sorting_desc");
        orderBy = "ASC";
      } else if (className == "sorting_desc") {
        $(e.target).addClass("sorting_desc").removeClass("sorting_asc");
        orderBy = "DESC";
      }
      this.getList(1, sortBy, orderBy);
    },

    onDeleteIncenTive() {
      var method = "DELETE";
      this.$api
        .webRequest({
          _method: method,
          _action: "incentives/" + this.delete_id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteIncenTive.closeModal();
            this.getList(1);
            this.$toast.success(res.message, {
              position: "top-right",
            });
          }
        })
        .catch((e) => {
          this.$refs.deleteIncenTive.removeLoader();
          this.$toast.error(e.message, {
            position: "top-right",
          });
        });
    },
    onExportReport() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "get-processing-fee-export",
          _body: this.filterObj,
          _buttonId: "cibil_report",
        })
        .then((res) => {
          if (res) {
            this.downloadPdf(res?.url, res?.file_name);
            this.$toast.success(res.message, { position: "top-right" });
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    downloadPdf(url, filename) {
      console.log(url, filename);
      var anchorElement = document.createElement("a");
      anchorElement.href = url;
      anchorElement.download = filename;
      anchorElement.target = "_blank";
      document.body.appendChild(anchorElement);
      console.log(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
    },
  },
};
</script>
