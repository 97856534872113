<template>
  <div class="worker_serch_warp">
    <div class="row d-flex justify-content-between">
      <div class="col-md-12 pr-2 mx-2">
        <h2 class="content-title">
          <a @click="$router.go(-1)" style="cursor: pointer"
            ><i class="fas fa-angle-left mr-2"></i>
            <span>View Loan Application</span>
          </a>
        </h2>

        <ul id="myTab" role="tablist" class="nav nav-tabs worker_tabs">
          <li class="nav-item">
            <a
              id="tab-1"
              data-toggle="tab"
              href="#tab_1"
              role="tab"
              aria-controls="tab_1"
              aria-selected="true"
              class="nav-link active"
              >Applicant Details</a
            >
          </li>
          <li class="nav-item">
            <a
              id="tab-2"
              data-toggle="tab"
              href="#tab_2"
              role="tab"
              aria-controls="tab_2"
              @click="getLOanApplicationInfo()"
              aria-selected="false"
              class="nav-link"
              >Guarantor Details</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="component-section no-code">
    <div id="myTabContent" class="tab-content">
      <div
        id="tab_1"
        role="tabpanel"
        aria-labelledby="tab-1"
        class="tab-pane fade active show"
      >
        <div class="row mx-0">
          <div class="col-lg-12">
            <div class="card rounded-2" style="border-radius: 10px">
              <div
                style="border-bottom: 1px solid #ccc"
                class="card-header pb-2 pt-3 d-flex flex-row justify-content-between"
              >
                <h4 class="text-bolder pt-3">Basic Details</h4>
                <div class="">
                  <button
                    v-if="running_loan_count > 0 && !loanApplication.parent_loan === null"
                    type="submit"
                    class="btn btn-brand-01 wb-save-btn mx-2"
                    id="mark-application-btn"
                    @click="openReNewLoan()"
                  >
                    Mark as Renew Loan
                  </button>
                  <button
                    v-if="running_loan_count > 0 && !loanApplication.parent_loan !== null"
                    type="submit"
                    class="btn btn-brand-01 wb-save-btn mx-2"
                    id="Remove-application-btn"
                    @click="openRemoveReNewLoan()"
                  >
                    Remove Renew Loan
                  </button>
                  <button
                    type="submit"
                    class="btn btn-brand-01 wb-save-btn mx-2"
                    id="edit_basic_info" 
                    @click="openResetLoanDetail()"
                  >
                    Edit Basic Info
                  </button>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Application Id</h5>
                      </div>
                      <div class="col-lg-8">
                        <h5 class="text-bolder">
                          {{ loanApplication.application_id }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4">
                        <h5
                          class="label-text"
                          v-if="loanApplication?.parent_loan != null"
                        >
                          Old Application Id
                        </h5>
                      </div>
                      <div class="col-lg-8">
                        <h5
                          class="text-bolder font-weight-bolder text-danger"
                          style="cursor: pointer !important"
                          v-if="loanApplication?.parent_loan != null"
                        >
                          <strong>{{
                            loanApplication?.parent_loan?.application_id
                          }}</strong>
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">CIN Number</h5>
                      </div>
                      <div class="col-lg-8 d-flex flex-row align-content-center">
                        <h5 class="text-bolder">
                          <a
                            style="text-decoration:underline; color:#005da9"
                            id="customer-btn"
                            target="_blank"
                            :href="'/customer-detail/' + user_uuid"
                            >{{ loanApplication?.user?.cin_number }}</a
                          >
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Applicant Name</h5>
                      </div>
                      <div class="col-lg-8 d-flex flex-row align-content-center">
                        <h5 class="text-bolder">{{ loanApplication.name }}</h5>
                        <!-- <i class="fa fa-edit mx-3 pt-1"  style="cursor: pointer"  @click="openResetLoanDetail()"></i>  -->
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Mobile Number</h5>
                      </div>
                      <div class="col-lg-8 d-flex flex-row">
                        <h5 class="text-bolder">
                          {{ loanApplication.mobile_number }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Alt Mobile Number 1</h5>
                      </div>
                      <div class="col-lg-8 d-flex flex-row">
                        <h5 class="text-bolder">
                          {{ loanApplication.alt_mobile_number_1 }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Alt Mobile Number 2</h5>
                      </div>
                      <div class="col-lg-8 d-flex flex-row">
                        <h5 class="text-bolder">
                          {{ loanApplication.alt_mobile_number_2 }}
                        </h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Permanent Address</h5>
                      </div>
                      <div class="col-lg-8 d-flex flex-row">
                        <h5 class="text-bolder">
                          {{
                            loanApplication?.home_address?.address
                              ? loanApplication?.home_address?.address
                              : "-"
                          }}
                          {{
                            loanApplication?.home_address?.city
                              ? ", " + loanApplication?.home_address?.city 
                              : ""
                          }}
                          {{
                            loanApplication?.home_address?.state?.name
                              ? ", " + loanApplication?.home_address?.state?.name
                              : ""
                          }}
                        </h5>
                        <i
                          v-if="access.can_update == 1"
                          class="fa fa-edit mx-3 pt-1"
                          title="Permanent Address"
                          style="cursor: pointer"
                          @click="onUpdateAddress('HOME_ADDRESS')"
                        ></i>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Residential Address</h5>
                      </div>
                      <div class="col-lg-8 d-flex flex-row">
                        <h5 class="text-bolder">
                          {{
                            loanApplication?.residential_address?.address
                              ? loanApplication?.residential_address?.address
                              : ""
                          }}
                          {{
                            loanApplication?.residential_address?.city
                              ? ", " + loanApplication?.residential_address?.city 
                              : ""
                          }}{{
                            loanApplication?.residential_address?.state?.name
                              ? ", " +  loanApplication?.residential_address?.state?.name
                              : ""
                          }}
                        </h5>
                        <i
                          v-if="access.can_update == 1"
                          class="fa fa-edit mx-3 pt-1"
                          title="Residential Address"
                          style="cursor: pointer"
                          @click="onUpdateAddress('RESIDENTIAL_ADDRESS')"
                        ></i>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4">
                        <h5 class="label-text">Office Address</h5>
                      </div>
                      <div class="col-lg-8 d-flex flex-row">
                        <h5 class="text-bolder">
                          {{
                            loanApplication?.office_address?.address
                              ? loanApplication?.office_address?.address
                              : ""
                          }}
                          {{
                            loanApplication?.office_address?.city
                              ? ", " + loanApplication?.office_address?.city
                              : ""
                          }}
                          {{
                            loanApplication?.office_address?.state?.name
                              ?  ", " + loanApplication?.office_address?.state?.name
                              : ""
                          }}
                        </h5>
                        <i
                          v-if="access.can_update == 1"
                          class="fa fa-edit mx-3 pt-1"
                          title="Office Address"
                          style="cursor: pointer"
                          @click="onUpdateAddress('OFFICE_ADDRESS')"
                        ></i>
                      </div>
                    </div>

                    <!-- <div class="row">
                      <div class="col-lg-3"></div>
                      <div class="col-sm-3 col-md-2 col-xl-3 col-lg-2">
                        <a
                          v-if="access.can_update == 1"
                          title="Permanent Address"
                          class="btn btn-brand-02 mx-1"
                          style="cursor: pointer"
                          @click="onUpdateAddress('HOME_ADDRESS')"
                          ><img src="/static/img/edit-icon.svg" alt="" />
                          Permanent Address</a
                        >
                      </div>
                      <div class="col-sm-3 col-md-2 col-xl-3 col-lg-2">
                        <a
                          v-if="access.can_update == 1"
                          title="Residential Address"
                          class="btn btn-brand-02 mx-1"
                          style="cursor: pointer"
                          @click="onUpdateAddress('RESIDENTIAL_ADDRESS')"
                          ><img src="/static/img/edit-icon.svg" alt="" />
                          Residential Address</a
                        >
                      </div>
                      <div class="col-sm-3 col-md-2 col-xl-3 col-lg-2">
                        <a
                          v-if="access.can_update == 1"
                          title="Office Address"
                          class="btn btn-brand-02 mx-2"
                          style="cursor: pointer"
                          @click="onUpdateAddress('OFFICE_ADDRESS')"
                          ><img src="/static/img/edit-icon.svg" alt="" /> Office
                          Address</a
                        >
                      </div>
                    </div> -->
                  </div>
                  <div
                    class="col-lg-2"
                    style="cursor: pointer"
                    v-if="application_status == 'FINAL_APPROVAL'"
                  >
                    <div class="">
                      <h5 class="text-bolder">Photo</h5>
                      <img
                        :src="loanApplication.applicant_photo"
                        class="img-fluid"
                        width="120"
                        v-if="loanApplication.applicant_photo"
                        v-on:click="openImage(loanApplication.applicant_photo, 0)"
                      />
                    </div>
                  </div>
                  <div
                    class="col-lg-2"
                    style="cursor: pointer"
                    v-if="application_status == 'FINAL_APPROVAL'"
                  >
                    <div class="">
                      <h5 class="text-bolder">Signature</h5>
                      <img
                        :src="loanApplication.applicant_thumb_img"
                        class="img-fluid"
                        width="120"
                        v-if="loanApplication.applicant_thumb_img"
                        v-on:click="openImage(loanApplication.applicant_thumb_img, 0)"
                      />
                    </div>
                  </div>
                  <div
                    class="col-lg-2"
                    style="cursor: pointer"
                    v-if="
                      application_status == 'FINAL_APPROVAL' &&
                      loanApplication.applicant_video != null
                    "
                  >
                    <div class="">
                      <h5 class="text-bolder">Video</h5>
                      <video
                        v-if="loanApplication.applicant_video"
                        controls
                        id="video-tag"
                        style="
                          width: 80% !important;
                          height: 153px;
                          border-radius: 10px;
                          object-fit: contain;
                        "
                      >
                        <source
                          style="
                            width: 80% !important;
                            height: 153px;
                            border-radius: 10px;
                            object-fit: contain;
                          "
                          id="video-source"
                          :src="loanApplication.applicant_video"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- LOan Requirement -->

          <div class="col-lg-12 pt-3">
            <div class="card rounded-2" style="border-radius: 10px">
              <div style="border-bottom: 1px solid #ccc" class="card-header pb-2 pt-3">
                <h4 class="text-bolder">Loan Requirement</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-3 d-flex flex-row">
                    <h5 class="label-text">Requested Loan Amount :</h5>
                    <h5 class="text-bolder mx-3">
                      {{ $helperService.getFormattedCurrency(requestedLoanAmont) }}
                    </h5>
                  </div>
                  <div
                    class="col-lg-3 d-flex flex-row"
                    v-if="application_status != 'PENDING_FOR_VERIFICATION'"
                  >
                    <h5 class="label-text">In-Principle Approval Amount :</h5>
                    <h5 class="text-bolder mx-3">
                      {{ $helperService.getFormattedCurrency(sanctionAmount) }}
                    </h5>
                  </div>
                  <div
                    class="col-lg-3 d-flex flex-row"
                    v-if="
                      application_status == 'DISBURSED' && has_cycle_date_changed == 1
                    "
                  >
                    <h5 class="label-text">Disbursed Date :</h5>
                    <h5 class="text-bolder mx-3">
                      {{ $helperService.getFormattedDateOnly(disbursement_date) }}
                    </h5>
                  </div>
                  <div
                    class="col-lg-3 d-flex flex-row"
                    v-if="
                      application_status == 'DISBURSED' && has_cycle_date_changed == 1
                    "
                  >
                    <h5 class="label-text">Cycle Date :</h5>
                    <h5 class="text-bolder mx-3">
                      {{ $helperService.getFormattedDateOnly(cycle_date) }}
                    </h5>
                  </div>
                  <div class="col-lg-4 d-flex flex-row">
                    <h5 class="label-text">Max Loan Approved Amount :</h5>
                    <h5 class="text-bolder mx-3">
                      {{ $helperService.getFormattedCurrency(max_approved_amount) }}
                    </h5>
                  </div>

                  <div
                    class="col-lg-4 d-flex flex-row"
                    v-if="
                      userType !== 'SUPER_ADMIN' &&
                      userType !== 'PARTNER' &&
                      userType !== 'BRANCH_STAFF' &&
                      userType !== 'ENQUIRY_USER' &&
                      userType !== 'DOCUMENT_VERIFY_USER'
                    "
                  >
                    <input
                      class="mx-2"
                      id="flexCheckChecked"
                      type="checkbox"
                      v-bind:true-value="1"
                      v-bind:false-value="0"
                      @change="changeLoanType($event)"
                      v-model="change_loan_type"
                    />
                    <label class="form-check-label pt-1" for="flexCheckChecked">
                      <u>Change Loan Type </u>
                    </label>
                  </div>
                </div>
                <div
                  :style="
                    'display:' + (application_status == 'PENDING' ? 'none' : 'block')
                  "
                >
                  <Form @submit="saveLoanRequirement">
                    <div class="row pt-2">
                      <div
                        :class="
                          has_change_narration === true
                            ? 'col-lg-2 col-sm-2'
                            : 'col-lg-3 col-sm-3'
                        "
                        v-if="application_status == 'PENDING_FOR_VERIFICATION'"
                      >
                        <label for="loan_amount">In-Principle Approval Amount</label>
                        <Field
                          name="loan_amount"
                          class="form-control"
                          rules="required:principle approval amount|numeric|multiply"
                          :validateOnInput="true"
                          v-model="requested_sanction_amount"
                          type="text"
                          placeholder="Enter Principle Approval Amount"
                          autocapitalize="false"
                        />
                        <ErrorMessage name="loan_amount" class="validation-msg" />
                      </div>
                      <div
                        :class="
                          has_change_narration === true
                            ? 'col-lg-2 col-sm-3'
                            : 'col-lg-3 col-sm-3'
                        "
                        v-else
                      >
                        <label for="loan_amount">Final Disburse Amount</label>
                        <Field
                          name="loan_amount"
                          class="form-control"
                          rules="required:final disburse amount|numeric|multiply"
                          :validateOnInput="true"
                          v-model="requested_disbursed_amount"
                          type="text"
                          placeholder="Enter Final Disburse Amount"
                          autocapitalize="false"
                        />
                        <ErrorMessage name="loan_amount" class="validation-msg" />
                      </div>

                      <div
                        :class="
                          has_change_narration === true
                            ? 'col-lg-3 col-sm-3'
                            : 'col-lg-3 col-sm-3'
                        "
                      >
                        <label for="loan_amount">Loan Type</label>
                        <Field
                          v-slot="{feild}"
                          name="loan_type"
                          rules="required:loan type,true"
                          :validateOnInput="true"
                          v-model="loan_type_id"
                          :disabled="
                            userType == 'BRANCH_STAFF' &&
                            userType !== 'ENQUIRY_USER' &&
                            userType !== 'DOCUMENT_VERIFY_USER'
                              ? true
                              : false
                          "
                        >
                          <Multiselect
                            v-bind="feild"
                            ref="loan_type"
                            :disabled="
                              userType == 'BRANCH_STAFF' &&
                              userType !== 'ENQUIRY_USER' &&
                              userType !== 'DOCUMENT_VERIFY_USER'
                                ? true
                                : false
                            "
                            mode="single"
                            trackBy="title"
                            label="title"
                            searchable="true"
                            valueProp="value"
                            placeholder="Select Loan Type"
                            class="form-control"
                            v-model="loan_type_id"
                            :options="loanTypeList"
                          />
                        </Field>
                        <ErrorMessage name="loan_type" class="validation-msg" />
                      </div>
                      <div
                        :class="
                          has_change_narration === true
                            ? 'col-lg-4 col-sm-4'
                            : 'col-lg-3 col-sm-3'
                        "
                        v-show="has_change_narration === true"
                      >
                        <label for="remark"
                          >Remark <span class="text-danger">*</span></label
                        >
                        <Field
                          :disabled="
                            userType == 'BRANCH_STAFF' &&
                            userType !== 'ENQUIRY_USER' &&
                            userType !== 'DOCUMENT_VERIFY_USER'
                              ? true
                              : false
                          "
                          name="remark"
                          id="remark"
                          class="form-control"
                          :rules="
                            has_change_narration === true
                              ? 'required:loan type change remark'
                              : ''
                          "
                          :validateOnInput="true"
                          v-model="change_narration"
                          type="text"
                          :placeholder="'Enter Loan Type Change Remark'"
                          autocapitalize="false"
                        />
                        <ErrorMessage name="remark" class="validation-msg" />
                      </div>
                      <div
                        v-show="application_status == 'DOCUMENT_VERIFIED'"
                        :class="
                          has_change_narration === true
                            ? 'col-lg-4 col-sm-4'
                            : 'col-lg-3 col-sm-3'
                        "
                      >
                        <label for="sanction_notes">Note</label>
                        <span class="text-danger">*</span>
                        <Field
                          name="sanction_notes"
                          id="sanction_notes"
                          class="form-control"
                          :rules="
                            application_status == 'DOCUMENT_VERIFIED'
                              ? 'required:' + 'sanction notes'
                              : ''
                          "
                          :validateOnInput="true"
                          v-model="sanction_notes"
                          type="text"
                          :placeholder="'Enter notes'"
                          autocapitalize="false"
                        />
                        <ErrorMessage name="sanction_notes" class="validation-msg" />
                      </div>
                      <div
                        :class="
                          has_change_narration === true
                            ? 'col-lg-2 col-sm-2 pt-4'
                            : 'col-lg-2 col-sm-2 pt-4'
                        "
                      >
                        <button
                          v-if="access.can_update == 1 && userType !== 'BRANCH_STAFF'"
                          type="submit"
                          class="btn btn-brand-01 wb-save-btn"
                          id="loan-application-btn"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
                <div class="row pt-3">
                  <div
                    class="col-lg-4 d-flex flex-row"
                    v-if="
                      (userType === 'SUPER_ADMIN' || userType === 'PARTNER') &&
                      change_narration !== null
                    "
                  >
                    <h6 class="bolder">Loan Type Change Remark :</h6>
                    <h6 class="text-bolder mx-3">
                      {{ change_narration }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- KYC Details -->
          <div class="col-lg-12 pt-3">
            <div class="card rounded-2" style="border-radius: 10px">
              <div style="border-bottom: 1px solid #ccc" class="card-header pb-2 pt-3">
                <div class="row">
                  <div class="col-lg-6">
                    <h4 class="text-bolder">KYC Details</h4>
                  </div>
                  <div class="col-lg-6 text-right" v-if="access.can_update == 1">
                    <a href="javascript:void(0)" @click="onUplaodKycDocument">
                      + Upload</a
                    >
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="table-responsive">
                      <table class="table table-primary mg-b-0">
                        <thead>
                          <tr>
                            <th scope="col">Document</th>
                            <th scope="col">Document No.</th>
                            <th scope="col">Updated By</th>
                            <th scope="col">Updated At</th>
                            <th scope="col" class="">Action</th>
                          </tr>
                        </thead>
                        <tbody v-if="kycLoanDocuments.length > 0">
                          <tr v-for="(item, i) in kycLoanDocuments" :key="item.id">
                            <td>{{ item?.title }}</td>
                            <td>{{ item?.doc_no }}</td>
                            <td>
                              {{ item?.added_by?.name ? item?.added_by?.name : "-" }}
                            </td>
                            <td>
                              <span>
                                {{
                                  $helperService.getFormattedDate(item?.updated_at)
                                }}</span
                              >
                            </td>

                            <td style="cursor: pointer">
                              <img
                                v-if="item.doc_img != null || item.doc_img == ''"
                                :src="
                                  item.doc_img
                                    ? item.doc_img
                                    : '/static/img/placeholder.png'
                                "
                                class="img-rounded"
                                style="width: 50px"
                                alt=""
                                v-on:click="openImage(item.doc_img, i)"
                              />

                              <a
                                href="javascript:void(0)"
                                v-if="item.doc_img != null"
                                @click="downloadPdf(item.doc_img, item.title)"
                                class="mx-2"
                                >Download</a
                              >
                              <a
                                href="javascript:void(0)"
                                @click="editKycDocument(item, 'EDIT')"
                                class="mx-2"
                                >Edit</a
                              >
                              <a
                                title="Delete"
                                class="mx-2"
                                style="cursor: pointer"
                                @click="onDeleteKycDocument(item)"
                                ><img src="/static/img/delete-icon.svg" alt=""
                              /></a>
                            </td>
                          </tr>
                        </tbody>
                        <template v-else>
                          <tr>
                            <td colspan="5">
                              <div class="text-center m-5">
                                <h3>Sorry,record not found.</h3>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Loan Documents Details -->
          <div class="col-lg-12 pt-3">
            <div class="card rounded-2" style="border-radius: 10px">
              <div style="border-bottom: 1px solid #ccc" class="card-header pb-2 pt-3">
                <div class="row">
                  <div class="col-lg-6">
                    <h4 class="text-bolder">Loan Documents</h4>
                  </div>
                  <div class="col-lg-6 text-right" v-if="access.can_update == 1">
                    <a href="javascript:void(0)" @click="onUplaodDocument"> + Upload</a>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="table-responsive">
                      <table class="table table-primary mg-b-0">
                        <thead>
                          <tr>
                            <th scope="col">Document</th>
                            <th scope="col">Updated By</th>
                            <th scope="col">Updated At</th>
                            <th scope="col" class="">Action</th>
                          </tr>
                        </thead>
                        <tbody v-if="documents.length > 0">
                          <tr v-for="(item, i) in documents" :key="item.id">
                            <td>{{ item?.title }}</td>
                            <td>
                              {{ item?.added_by?.name ? item?.added_by?.name : "-" }}
                            </td>
                            <td>
                              <span>
                                {{
                                  $helperService.getFormattedDate(item?.updated_at)
                                }}</span
                              >
                            </td>

                            <td
                              v-if="item.image != null || item.image == ''"
                              style="cursor: pointer"
                            >
                              <img
                                :src="
                                  item.image ? item.image : '/static/img/placeholder.png'
                                "
                                class="img-rounded"
                                style="width: 50px"
                                alt=""
                                v-on:click="openImage(item.image, i)"
                              />

                              <a
                                href="javascript:void(0)"
                                @click="downloadPdf(item.image, item.title)"
                                class="mx-2"
                                >Download</a
                              >
                            </td>
                            <td v-else>-</td>
                          </tr>
                        </tbody>
                        <template v-else>
                          <tr>
                            <td colspan="4">
                              <div class="text-center m-5">
                                <h3>Sorry,record not found.</h3>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Verification Checklist -->
          <div
            class="col-lg-12 pt-3"
            
          >
            <div class="card rounded-2" style="border-radius: 10px" v-if="checklist.length > 0 && application_status != 'PENDING'">
              <div style="border-bottom: 1px solid #ccc" class="card-header pb-2 pt-3">
                <h4 class="text-bolder">Verification Checklist</h4>
              </div>
              <div class="card-body">
                <div class="table-responsive" style="min-height: auto">
                  <table class="table table-primary mg-b-0">
                    <thead>
                      <tr>
                        <th>Verification List</th>
                        <th>Verified By</th>
                        <th>Verified at</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="item in checklist" :key="item.id">
                        <tr v-if="item.doc_type == 'CHECKLIST'">
                          <td>
                            <div class="form-check">
                              <input
                                :disabled="
                                  application_status != 'PENDING_FOR_VERIFICATION'
                                "
                                class="form-check-input"
                                @change="checkPermission($event, item)"
                                v-model="item.has_checked"
                                true-value="1"
                                false-value="0"
                                type="checkbox"
                                value=""
                                :id="item.id"
                              />
                              <label class="form-check-label" :for="item.id">
                                {{ item.title }}
                              </label>
                            </div>
                          </td>
                          <td v-if="item.added_by != null">
                            {{ item?.added_by?.name }}
                          </td>
                          <td v-else>-</td>
                          <td v-if="item.updated_at != null">
                            <span>
                              {{
                                $helperService.getFormattedDate(item?.updated_at)
                              }}</span
                            >
                          </td>
                          <td v-else>-</td>
                          <td
                            v-if="
                              item.type == 'LOCATION_PIN' &&
                              item.latitude != null &&
                              item.longitude != null
                            "
                          >
                            <a
                              title="View Map"
                              class="mx-2"
                              style="cursor: pointer"
                              @click="OpenMapModel(item)"
                              ><img
                                src="/static/img/location.png"
                                alt=""
                                style="width: 22px !important"
                            /></a>
                          </td>
                          <td v-else>-</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              class="pt-3 d-flex justify-content-center"
              v-if="
                application_status == 'PENDING_FOR_VERIFICATION' && access.can_update == 1
              "
            >
              <button
                id="save-btn"
                type="button" v-if="checklist.length > 0 && application_status == 'PENDING_FOR_VERIFICATION'"
                @click="saveCheckList('SAVE')"
                class="btn btn-brand-01 wb-cancel-btn mr-lg-3 mr-2 mx-3"
              >
                Save
              </button>
              <button
                type="button" v-if="checklist.length > 0 && application_status  == 'PENDING_FOR_VERIFICATION'"
                :disabled="!select_all"
                @click="saveCheckList('NEXT')"
                class="btn btn-brand-01 wb-save-btn"
                id="status-btn"
              >
                Complete Verification
              </button>

              <button
                id="cancel-btn"
                type="button" v-if="application_status == 'PENDING_FOR_VERIFICATION'"
                @click="onUpdateStatus('REJECTED', 'loan/' + this.id + '/status')"
                class="btn btn-danger wb-cancel-btn mx-2"
              >
                Reject
              </button>
            </div>
          </div>

          <!-- Assign to verification: -->
          <div class="col-lg-12 pt-3" v-if="loanApplication">
            <div class="card rounded-2" style="border-radius: 10px">
              <div class="card-body p-3 p-lg-3">
                <div class="row">
                  <div class="col-lg-3 col-sm-6">
                    <h6><b>Assign to Verification:</b></h6>
                    <div>{{ loanApplication?.verification_officer?.name }}</div>
                  </div>
                  <div class="col-lg-3 col-sm-6">
                    <h6><b>Mobile Number:</b></h6>
                    <div>
                      {{ loanApplication?.verification_officer?.mobile_number }}
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6">
                    <h6><b>Sanction comment:</b></h6>
                    <div>{{ sanctionNarration }}</div>
                  </div>
                  <div
                    class="col-lg-3 col-sm-6"
                    v-if="loanApplication?.verified_by != null"
                  >
                    <div><b>Verified At:</b></div>
                    <div>
                      {{
                        $helperService.getFormattedDate(
                          loanApplication?.verified_by?.completed_at
                        )
                      }}
                    </div>
                  </div>

                  <div
                    class="col-lg-3"
                    v-if="
                      application_status == 'PENDING_FOR_VERIFICATION' &&
                      access.can_update == 1
                    "
                  >
                    <button
                      class="btn btn-brand-01 wb-save-btn"
                      title="Assign Verification Officer"
                      @click="openAssignOfficer"
                    >
                      <img src="/static/img/edit-icon.svg" alt="" /> Assign
                    </button>
                  </div>
                </div>
                <!-- <div class="row pt-3" v-if="loanApplication?.verified_by != null">
                                    <div class="col-lg-4 col-sm-6">
                                        <h6><b>Verified By :</b></h6>
                                        <div>{{ loanApplication?.verified_by?.name }}</div>
                                    </div>

                                    <div class="col-lg-4 col-sm-6">
                                        <div><b>Verified At:</b></div>
                                        <div>{{
                                            $helperService.getFormattedDate(loanApplication?.verified_by?.completed_at)
                                        }}</div>
                                    </div>
                                </div> -->
              </div>
            </div>
          </div>

          <div
            class="col-lg-12 pt-3"
            v-if="
              application_status == 'DOCUMENT_REJECTED' ||
              application_status == 'REJECTED' ||
              application_status == 'VERIFICATION_REJECTED' ||
              application_status == 'APPLICATION_REJECTED'
            "
          >
            <div
              class="card rounded-2"
              style="border-radius: 10px"
              v-if="loanApplication.rejected_by != null"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-3">
                    <h5 class="label-text">Rejected Name</h5>
                    <h5 class="label-text">Rejected Date</h5>
                    <h5 class="label-text">Rejected reason</h5>
                  </div>
                  <div class="col-lg-7">
                    <h5 class="text-bolder">
                      {{ loanApplication.rejected_by?.name }}
                    </h5>
                    <h5 class="text-bolder">
                      {{
                        $helperService.getFormattedDate(
                          loanApplication.rejected_by?.rejected_at
                        )
                      }}
                    </h5>
                    <h5 class="text-bolder">{{ loanApplication.narration }}</h5>
                  </div>

                  <div
                    class="col-lg-2"
                    v-if="
                      (application_status == 'APPLICATION_REJECTED' ||
                        application_status == 'VERIFICATION_REJECTED' ||
                        application_status == 'DOCUMENT_REJECTED') &&
                      access.can_update == 1
                    "
                  >
                    <button
                      class="btn btn-brand-01 wb-save-btn"
                      title="ReAssig"
                      @click="openReAssignRejectedApplication()"
                    >
                      <img src="/static/img/edit-icon.svg" alt="" /> Re Assign
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-lg-12 pt-3"
            v-if="application_status == 'PENDING' && access.can_update == 1"
          >
            <div class="row">
              <div class="col-lg-12 d-flex justify-content-center">
                <button
                  id="cancel-btn"
                  type="button"
                  @click="onUpdateStatus('REJECTED', 'loan/' + this.id + '/status')"
                  class="btn btn-danger wb-cancel-btn mx-2"
                >
                  Reject
                </button>
                <button
                  type="submit"
                  @click="onUpdateStatus('APPROVED', 'loan/' + this.id + '/status')"
                  class="btn btn-brand-01 wb-save-btn"
                  id="status-btn"
                >
                  Send for Verification
                </button>
              </div>
            </div>
          </div>

          <!-- <div class="col-lg-12 pt-3" v-if="application_status == 'PENDING_FOR_VERIFICATION' && access.can_update == 1">
                        <div class="row">
                            <div class="col-lg-12 d-flex justify-content-center">
                                <button id="save-btn" type="button" @click="saveCheckList('SAVE')"
                                    class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2">
                                    Save
                                </button>

                            </div>
                        </div>
                    </div> -->

          <div
            class="col-lg-12 pt-3"
            v-if="
              (application_status == 'FINAL_APPROVAL' ||
                (application_status == 'DISBURSED' && has_cycle_date_changed == 1)) &&
              access.can_update == 1
            "
          >
            <div class="row">
              <div class="col-lg-12 d-flex justify-content-center">
                <button
                  id="save-btn"
                  type="button"
                  @click="onApprove()"
                  class="btn btn-brand-01 wb-save-btn"
                >
                  Final Approve
                </button>
                <button
                  type="button"
                  @click="onRejectModal()"
                  class="btn btn-danger wb-cancel-btn mx-2"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="tab_2" role="tabpanel" aria-labelledby="tab-2" class="tab-pane fade">
        <div class="row">
          <div class="col-lg-12 px-5">
            <div class="card rounded-5">
              <div class="card-body p-3 p-lg-3">
                <div class="row">
                  <div
                    class="col-12 text-right"
                    v-if="application_status == 'DOCUMENT_VERIFIED'"
                  >
                    <a
                      @click="addGuarantor()"
                      v-if="access.can_create == 1"
                      class="btn btn-brand-02"
                      >+ Add
                    </a>
                  </div>
                </div>
                <hr />
                <div class="table-responsive" style="min-height: auto">
                  <table class="table table-primary mg-b-0">
                    <thead>
                      <tr>
                        <th>Guarantor Name</th>
                        <th>Guarantor No.</th>
                        <th>Photo</th>
                        <th>Signature</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="guarantorList.length > 0">
                      <tr v-for="guarantor in guarantorList" :key="guarantor.id">
                        <td>{{ guarantor.guarantor_name }}</td>
                        <td>+91 {{ guarantor.mobile_number }}</td>
                        <td>
                          <img
                            :src="guarantor.guarantor_photo"
                            class="img-fluid"
                            width="80"
                            height="80"
                            v-if="guarantor.guarantor_photo"
                          />
                        </td>
                        <td>
                          <img
                            :src="guarantor.guarantor_thumb_img"
                            class="img-fluid"
                            width="80"
                            height="80"
                            v-if="guarantor.guarantor_thumb_img"
                          />
                        </td>
                        <td>
                          <a
                            title="view"
                            class="mx-2"
                            style="cursor: pointer"
                            @click="editGuarantor(guarantor)"
                            ><img src="/static/img/eye-icon.svg" alt=""
                          /></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <template v-if="guarantorList.length == 0">
                    <div class="text-center m-5">
                      <h3>Sorry,record not found.</h3>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="viewModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-sm-12 text-center">
              <img :src="url" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade wb-modal-wrapper"
    id="uploadLoanDocument"
    tabindex="-1"
    role="dialog"
    aria-labelledby="uploadLoanDocument"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a class="close mr-3 mt-3 text-right" @click="closeModal()" aria-label="Close">
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Upload Document</span>
          </h5>
        </div>
        <Form
          @submit="onUploadLoanDocument"
          class="columns column is-multiline is-12"
          ref="onUploadLoanDocument"
        >
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="signin-form">
              <div class="col-lg-12 mb-4">
                <label
                  >Document Type
                  <span class="text-danger">*</span>
                </label>
                <Field
                  v-slot="{field}"
                  name="document_type"
                  rules="required:document type,true"
                  :validateOnInput="true"
                  v-model="loan_title"
                >
                  <Multiselect
                    v-bind="field"
                    ref="document_type_multiselect"
                    mode="single"
                    trackBy="title"
                    label="title"
                    valueProp="title"
                    placeholder="Select Document Type"
                    class="form-control"
                    v-model="loan_title"
                    :options="documentList"
                    :searchable="true"
                    :createOption="true"
                  />
                </Field>
                <ErrorMessage name="document_type" class="validation-msg" />
              </div>

              <div class="col-lg-12 mb-4" v-if="loan_title == 'Other'">
                <div class="form-group">
                  <label
                    >Document Title
                    <span class="text-danger">*</span>
                  </label>
                  <Field
                    name="loan_title"
                    v-model="document_narration"
                    class="form-control"
                    rules="required:document title"
                    :validateOnInput="true"
                    type="text"
                    placeholder="Document Title"
                    autocapitalize="false"
                  />
                  <ErrorMessage name="loan_title" class="validation-msg" />
                </div>
              </div>

              <div class="col-lg-12 mb-4">
                <div class="form-group">
                  <label
                    >Document Type
                    <span class="text-danger">*</span>
                  </label>
                  <div
                    class="wb-browse-file"
                    style="cursor: pointer"
                    @click="selectFile($event)"
                  >
                    <Field
                      name="upload_file"
                      v-model="fileName"
                      type="text"
                      placeholder="Browse File"
                      class="form-control"
                      :disabled="disabled == 1"
                    />
                    <Field
                      id="selectFile"
                      name="upload_file"
                      accept=".doc, .docx,.ppt, .pptx,.txt,.pdf,.jpeg,.jpg,.png,.webp"
                      style="display: none"
                      type="file"
                      placeholder="Browse File"
                      @change="onDocumentChange($event)"
                      class="form-control"
                    />
                    <a style="cursor: pointer" @click="selectFile($event)"
                      ><img src="/static/img/browse-icon.svg" alt=""
                    /></a>
                  </div>
                  <ErrorMessage name="upload_file" class="validation-msg" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="closeModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-brand-01 wb-save-btn"
              id="document-upload"
            >
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <div
    class="modal fade wb-modal-wrapper"
    id="openRejectNarration"
    tabindex="-1"
    role="dialog"
    aria-labelledby="openRejectNarration"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeNarrationModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center" v-if="reason_type == 'REJECTED'">
          <h5 class="modal-title font-22">
            <span>Reason</span>
          </h5>
        </div>
        <div class="modal-header justify-content-center" v-if="reason_type == 'APPROVED'">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>
        <Form
          @submit="onAddRejectNarration"
          class="columns column is-multiline is-12"
          ref="onAddRejectNarration"
        >
          <div class="modal-body text-center" v-if="reason_type == 'APPROVED'">
            <h5>Are you sure you want to verify this application ?</h5>
          </div>
          <div
            class="modal-body form-style pb-0 px-lg-5"
            v-if="reason_type == 'REJECTED'"
          >
            <div class="signin-form">
              <div class="form-group">
                <label
                  >Reason
                  <span class="text-danger">*</span>
                </label>
                <Field
                  v-slot="{field}"
                  name="narration"
                  rules="required:reason,true"
                  :validateOnInput="true"
                  v-model="narration"
                >
                  <textarea
                    v-bind="field"
                    rows="5"
                    cols="5"
                    valueProp="id"
                    placeholder="Enter Reject Reason"
                    class="form-control"
                    v-model="narration"
                  />
                </Field>
                <ErrorMessage name="narration" class="validation-msg" />
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="closeNarrationModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-brand-01 wb-cancel-btn"
              id="confirmation-btn"
              v-if="reason_type == 'APPROVED'"
            >
              Verify
            </button>
            <button
              type="submit"
              class="btn btn-danger wb-cancel-btn"
              id="confirmation-btn"
              v-if="reason_type == 'REJECTED'"
            >
              Reject
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <div
    class="modal fade wb-modal-wrapper"
    id="statusModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="statusModal"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeNarrationModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>
        <div class="modal-body text-center">
          <h5>Are you sure you want to accept this application?</h5>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            type="button"
            class="btn btn-brand-01 wb-cancel-btn"
            id="confirmation-btn"
            @click="setStatusUrl('loan/' + this.id + '/verification/status')"
          >
            Accept
          </button>
          <button
            type="button"
            @click="
              onUpdateStatus('REJECTED', 'loan/' + this.id + '/verification/status')
            "
            class="btn btn-danger wb-cancel-btn"
            id="confirmation-btn"
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="updateRejectedStatus"
    tabindex="-1"
    role="dialog"
    aria-labelledby="updateRejectedStatus"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="closeUpdatedRejectedModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>
        <div class="modal-body text-center">
          <h5>Are you sure you want to Re-Assign this application?</h5>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            type="button"
            class="btn btn-brand-01 wb-cancel-btn"
            id="status-btn-reject"
            @click="closeUpdatedRejectedModal()"
          >
            Cancel
          </button>
          <button
            type="button"
            @click="updateReasignApplication()"
            class="btn btn-brand-01 wb-save-btn"
            id="status-btn"
          >
            Approve
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade wb-modal-wrapper"
    id="onApprove"
    tabindex="-1"
    role="dialog"
    aria-labelledby="onApprove"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="approveCloseModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Choose 1st EMI Date</span>
          </h5>
        </div>
        <Form
          @submit="disbursed"
          class="columns column is-multiline is-12"
          ref="disbursed"
        >
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="signin-form">
              <div class="form-group">
                <label
                  >Disbursement Date
                  <span class="text-danger">*</span>
                </label>
                <!-- <Field name="disbursed_at" class="form-control" id="disbursed_at"
                                    rules="required" :validateOnInput="true" v-model="disbursed_at"
                                    type="date" placeholder="Disbursement Date" autocapitalize="false" />
                                <ErrorMessage name="disbursed_at" class="validation-msg" /> -->
                <Field
                  name="disbursed_at"
                  class="form-control"
                  id="disbursed_at"
                  rules="required:Disbursement date"
                  :validateOnInput="true"
                  v-model="disbursed_at"
                  type="date"
                  placeholder="Cycle Date"
                  @change="setActualDate($event)"
                  autocapitalize="false"
                />
                <ErrorMessage name="disbursed_at" class="validation-msg" />
              </div>
              <div class="form-group">
                <label
                  >Cycle Date
                  <span class="text-danger">*</span>
                </label>
                <Field
                  :disabled="
                    (userType === 'BRANCH_MANAGER' ||
                      userType === 'BRANCH_STAFF' ||
                      userType == 'BRANCH_STAFF' ||
                      userType !== 'ENQUIRY_USER' ||
                      userType !== 'DOCUMENT_VERIFY_USER') &&
                    disable == true
                  "
                  name="cycle_start_date"
                  class="form-control"
                  id="cycle_start_date"
                  rules="required:Cycle date"
                  :validateOnInput="true"
                  v-model="cycle_start_date"
                  type="date"
                  placeholder="Cycle Date"
                  autocapitalize="false"
                  @change="setActualDate($event)"
                />
                <ErrorMessage name="cycle_start_date" class="validation-msg" />
              </div>
              <div
                class="form-group"
                v-show="userType === 'BRANCH_MANAGER' || userType === 'BRANCH_STAFF'"
              >
                <div class="text-right">
                  <input
                    class="mx-2"
                    id="flexCheckChecked"
                    type="checkbox"
                    v-bind:true-value="1"
                    v-bind:false-value="0"
                    @change="changeCycleDate($event)"
                    v-model="has_cycle_date_changed"
                  />
                  <label
                    class="form-check-label link-underline-primary"
                    for="flexCheckChecked"
                  >
                    <u>Change Cycle Date </u>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="approveCloseModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button type="submit" class="btn btn-brand-01 wb-save-btn" id="cycle-update">
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="viewAddressModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="addressType == 'HOME_ADDRESS'">
            Permanent Address
          </h5>
          <h5 class="modal-title" v-if="addressType == 'OFFICE_ADDRESS'">
            Office Address
          </h5>
          <h5 class="modal-title" v-if="addressType == 'RESIDENTIAL_ADDRESS'">
            Residential Address
          </h5>
          <button
            type="button"
            class="close"
            @click="onCloseAddress()"
            aria-label="Close"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <Form @submit="onUpdateAddresses" class="columns column" ref="addressUpdate">
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <div
                    class="col-sm-12 mb-3 pt-2"
                    v-if="
                      addressType == 'RESIDENTIAL_ADDRESS' ||
                      addressType == 'HOME_ADDRESS'
                    "
                  >
                    <label> Address Type </label>
                    <div class="">
                      <span class="mr-3">
                        <input
                          class=""
                          v-model="addresses.has_owned"
                          :checked="addresses.has_owned == 0 ? true : false"
                          type="radio"
                          value="0"
                          id="Rented"
                          style="width: 20px; height: auto"
                        />
                        <label class="form-check-label" for="Rented"> Rented </label>
                      </span>
                      <span>
                        <input
                          class=""
                          v-model="addresses.has_owned"
                          :checked="addresses.has_owned == 1 ? true : false"
                          type="radio"
                          value="1"
                          id="Owned"
                          style="width: 20px; height: auto"
                        />
                        <label class="form-check-label" for="Owned"> Owned </label>
                      </span>
                    </div>

                    <ErrorMessage name="gender" class="validation-msg" />
                  </div>

                  <div class="col-sm-12 mb-3">
                    <label>
                      Address
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="address"
                      id="address"
                      rules="required:address"
                      class="form-control"
                      v-model="addresses.address"
                      type="text"
                      v-bind:placeholder="'Address'"
                    />
                    <ErrorMessage name="address" class="validation-msg" />
                  </div>
                  <div class="col-sm-12 mb-3">
                    <label>
                      City
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="city"
                      id="city"
                      rules="required:city"
                      class="form-control"
                      v-model="addresses.city"
                      type="text"
                      v-bind:placeholder="'City'"
                    />
                    <ErrorMessage name="city" class="validation-msg" />
                  </div>
                  <div class="col-sm-12 mb-3">
                    <label>
                      State
                      <span class="text-danger">*</span>
                    </label>

                    <Field
                      v-slot="{field}"
                      name="state"
                      rules="required:state list,true"
                      :validateOnInput="true"
                      v-model="addresses.state_id"
                    >
                      <Multiselect
                        v-bind="field"
                        rules="required:state,true"
                        ref="state_multiselect"
                        mode="single"
                        trackBy="name"
                        label="name"
                        valueProp="id"
                        placeholder="Select State"
                        class="form-control"
                        searchable="true"
                        v-model="addresses.state_id"
                        :options="stateList"
                      />
                    </Field>
                    <ErrorMessage name="state" class="validation-msg" />
                  </div>

                  <div class="col-sm-12 mb-3">
                    <label>
                      Pincode
                      <span class="text-danger">*</span></label
                    >
                    <Field
                      name="pincode"
                      id="pincode"
                     rules="required:pincode|pincode"
                      class="form-control"
                      v-model="addresses.pincode"
                      type="text"
                      v-bind:placeholder="'Pincode'"
                    />
                    <ErrorMessage name="pincode" class="validation-msg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-12 text-center">
              <button
                type="button"
                @click="onCloseAddress()"
                class="btn btn-brand-01 wb-save-btn mx-2"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-brand-01 wb-save-btn" id="address-btn">
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="assignVerificationOfficer"
    tabindex="-1"
    aria-labelledby="assignVerificationOfficer"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Assign Verification Officer</h5>
          <button
            type="button"
            class="close"
            @click="onCloseAssignOfficer()"
            aria-label="Close"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <Form
          @submit="onUpdateVerificationOfficer"
          class="columns column"
          ref="onAssignVerification"
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-sm-12 mb-3">
                    <label>
                      Verification Officer
                      <span class="text-danger">*</span>
                    </label>
                    <Field
                      v-slot="{field}"
                      name="officer_uuid"
                      rules="required:verification officer,true"
                      :validateOnInput="true"
                      v-model="officer_uuid"
                    >
                      <Multiselect
                        v-bind="field"
                        ref="multiselect"
                        trackBy="name"
                        rules="required"
                        label="name"
                        valueProp="uuid"
                        placeholder="Select Verification Officer"
                        class="form-control"
                        v-model="officer_uuid"
                        :options="officers"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="officer_uuid" class="validation-msg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-12 text-center">
              <button
                type="button"
                @click="onCloseAssignOfficer()"
                class="btn btn-brand-01 wb-save-btn mx-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-brand-01 wb-save-btn mx-2"
                id="save-btn"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>

  <div
    class="modal fade wb-modal-wrapper"
    id="reNewLoan"
    tabindex="-1"
    role="dialog"
    aria-labelledby="reNewLoan"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="onCloseReNewLoan()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>
        <Form
          @submit="onReLoanApplication"
          class="columns column"
          ref="onAssignVerification"
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-sm-12 mb-3">
                    <label>
                      Loan Application
                      <span class="text-danger">*</span>
                    </label>
                    <Field
                      v-slot="{field}"
                      name="officer_uuid"
                      rules="required:loan application,true"
                      :validateOnInput="true"
                      v-model="renew_application_id"
                    >
                      <Multiselect
                        v-bind="field"
                        ref="multiselect"
                        trackBy="uuid"
                        rules="required"
                        label="application_id"
                        valueProp="uuid"
                        placeholder="Select Loan Application"
                        class="form-control"
                        v-model="renew_application_id"
                        :options="loanApplications"
                        searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="officer_uuid" class="validation-msg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-12 text-center">
              <button
                type="button"
                @click="onCloseAssignOfficer()"
                class="btn btn-brand-01 wb-save-btn mx-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-brand-01 wb-save-btn mx-2"
                id="renew-loan-btn"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="reassignVerificationOfficer"
    tabindex="-1"
    role="dialog"
    aria-labelledby="reassignVerificationOfficer"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="onCloseReAssignOfficer()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>
        <div class="modal-body form-style pb-0 px-lg-5">
          <h5>Are you sure,you want to Re-Assign the application?</h5>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            id="cancel-btn"
            @click="onCloseReAssignOfficer()"
            type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            No
          </button>
          <button
            type="button"
            @click="onUpdateReassign()"
            class="btn btn-brand-01 wb-save-btn"
            id="status-btn"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="reRemoveNewLoan"
    tabindex="-1"
    role="dialog"
    aria-labelledby="reRemoveNewLoan"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="onCloseRemoveReNewLoan()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>
        <div class="modal-body form-style pb-0 px-lg-5">
          <h5>Are you sure,you want to unlink the parent loan application?</h5>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            id="cancel-btn"
            @click="onCloseRemoveReNewLoan()"
            type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            No
          </button>
          <button
            type="button"
            @click="onDeleteRenewApplication()"
            class="btn btn-brand-01 wb-save-btn"
            id="delete-renew-btn"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="rejectApplication"
    tabindex="-1"
    role="dialog"
    aria-labelledby="rejectApplication"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="onCloseRejectModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Confirmation</span>
          </h5>
        </div>
        <div class="modal-body form-style pb-0 px-lg-5">
          <h5>Are you sure, you want to reject the application?</h5>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            id="cancel-btn"
            @click="onCloseRejectModal()"
            type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            No
          </button>
          <button
            type="button"
            @click="onUpdateStatus('REJECTED', 'loan/' + this.id + '/reject')"
            class="btn btn-brand-01 wb-save-btn"
            id="status-btn"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="openGooglemap"
    tabindex="-1"
    role="dialog"
    aria-labelledby="openGooglemap"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a class="close mr-3 mt-3 text-right" @click="closeMapModal()" aria-label="Close">
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Map</span>
          </h5>
        </div>
        <div class="modal-body form-style pb-0 px-lg-5">
          <div class="row">
            <div class="col-lg-12">
              <iframe
                :src="
                  'https://www.google.com/maps?q=' +
                  maps?.latitude +
                  ',' +
                  maps?.longitude +
                  '&hl=en&z=14&amp;output=embed'
                "
                style="width: 100%; height: 500px !important"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                title="Google Map"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 justify-content-center mb-3">
          <button
            id="cancel-btn"
            @click="closeMapModal()"
            type="button"
            class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="uploadKycDocs"
    tabindex="-1"
    role="dialog"
    aria-labelledby="uploadKycDocs"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="onCloseUplaodKycModal()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Upload Document</span>
          </h5>
        </div>
        <Form
          @submit="onUploadkycDetails"
          class="columns column is-multiline is-12"
          ref="onUploadkycDetails"
        >
          <div class="modal-body form-style pb-0 px-lg-5">
            <div class="signin-form">
              <div class="col-lg-12 mb-4">
                <label
                  >Document Type
                  <span class="text-danger">*</span>
                </label>
                <Field
                  v-slot="{field}"
                  name="document_type"
                  rules="required:document type,true"
                  :validateOnInput="true"
                  v-model="kycDocuments.doc_id"
                >
                  <Multiselect
                    v-bind="field"
                    ref="document_type_multiselect"
                    mode="single"
                    trackBy="title"
                    label="title"
                    valueProp="uuid"
                    @select="afterValueChange"
                    placeholder="Select Document Type"
                    class="form-control"
                    v-model="kycDocuments.doc_id"
                    :options="kycDocumentList"
                    :searchable="true"
                    :createOption="true"
                  />
                </Field>
                <ErrorMessage name="document_type" class="validation-msg" />
              </div>

              <div class="col-lg-12 mb-4">
                <div class="form-group">
                  <label>
                    Document Number
                    <span class="text-danger" v-if="is_optional == 0">*</span>
                  </label>
                  <Field
                    name="doc_number"
                    v-model="kycDocuments.doc_no"
                    class="form-control"
                    :rules="
                      is_optional == 0
                        ? documentTitle == 'AADHAAR_CARD'
                          ? 'required:document number,true|aadhaar'
                          : documentTitle == 'PAN_CARD'
                          ? 'required:document number,true|PAN'
                          : ''
                        : ''
                    "
                    :validateOnInput="true"
                    type="text"
                    placeholder="Document number"
                    autocapitalize="false"
                  />
                  <ErrorMessage name="doc_number" class="validation-msg" />
                </div>
              </div>

              <div class="col-lg-12 mb-4" v-if="has_additional_info == 1">
                <div class="form-group">
                  <label
                    >Issue Date
                    <span class="text-danger" v-if="has_additional_info == 1">*</span>
                  </label>
                  <Field
                    name="issue_date"
                    class="form-control"
                    v-slot="{date}"
                    :rules="has_additional_info == 1 ? 'required:issue date,true' : ''"
                    id="issue_date"
                    v-model="kycDocuments.issue_date"
                  >
                    <VueDatePicker
                      v-bind="date"
                      placeholder="Select Issue Date "
                      style="height: 46px !important"
                      v-model="kycDocuments.issue_date"
                      :type="boolean"
                      :format="'dd/MM/yyyy'"
                      :default="true"
                      :enable-time-picker="false"
                    >
                    </VueDatePicker>
                  </Field>
                  <ErrorMessage name="issue_date" class="validation-msg" />
                </div>
              </div>

              <div class="col-lg-12 mb-4" v-if="has_additional_info == 1">
                <div class="form-group">
                  <label
                    >Expiry Date
                    <span class="text-danger" v-if="has_additional_info == 1">*</span>
                  </label>
                  <Field
                    name="expired_date"
                    class="form-control"
                    v-slot="{date}"
                    :rules="has_additional_info == 1 ? 'required:expiry date,true' : ''"
                    id="expired_date"
                    v-model="kycDocuments.expiry_date"
                  >
                    <VueDatePicker
                      v-bind="date"
                      placeholder="Select Expired Date "
                      style="height: 46px !important"
                      v-model="kycDocuments.expiry_date"
                      :type="boolean"
                      :format="'dd/MM/yyyy'"
                      :default="true"
                      :enable-time-picker="false"
                    >
                    </VueDatePicker>
                  </Field>
                  <ErrorMessage name="expired_date" class="validation-msg" />
                </div>
              </div>
              <div class="col-lg-12 mb-4">
                <div class="form-group">
                  <label
                    >Document
                    <!-- <span class="text-danger">*</span> -->
                  </label>
                  <div
                    class="wb-browse-file"
                    style="cursor: pointer"
                    @click="selectKycFile($event)"
                  >
                    <Field
                      name="upload_kyc_file"
                      v-model="kycFileName"
                      type="text"
                      placeholder="Browse File"
                      class="form-control"
                      :disabled="disabled == 1"
                    />
                    <Field
                      id="selectKycFile"
                      name="upload_kyc_file"
                      accept=".doc, .docx,.ppt, .pptx,.txt,.pdf,.jpeg,.jpg,.png,.webp"
                      style="display: none"
                      type="file"
                      placeholder="Browse File"
                      @change="onKycDocumentChange($event)"
                      class="form-control"
                    />
                    <a style="cursor: pointer" @click="selectKycFile($event)"
                      ><img src="/static/img/browse-icon.svg" alt=""
                    /></a>
                  </div>
                  <ErrorMessage name="upload_kyc_file" class="validation-msg" />
                </div>
                <a
                  v-if="downloadKycDocument != null"
                  style="cursor: pointer"
                  @click="downloadPdf(downloadKycDocument, 'kyc Document')"
                  ><img
                    src="/static/img/download-icon.svg"
                    class="mx-3"
                    alt=""
                  />Download</a
                >
              </div>
            </div>
          </div>
          <div class="modal-footer border-0 justify-content-center mb-3">
            <button
              id="cancel-btn"
              @click="onCloseUplaodKycModal()"
              type="button"
              class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-brand-01 wb-save-btn"
              id="loan-kyc-document"
            >
              Save
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <div
    class="modal fade wb-modal-wrapper"
    id="ResetLoanDetail"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ResetLoanDetail"
    aria-hidden="true"
    style="pointer-events: none"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <a
          class="close mr-3 mt-3 text-right"
          @click="onCloseResetLoanDetail()"
          aria-label="Close"
        >
          <img src="/static/img/close-icon.svg" width="20" />
        </a>
        <div class="modal-header justify-content-center">
          <h5 class="modal-title font-22">
            <span>Applicant Info</span>
          </h5>
        </div>
        <Form @submit="OnReSetLoanDetails" class="columns column" ref="ResetLoanDetail">
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 mb-4">
                <label> Name <span class="text-danger">*</span> </label>
                <Field
                  name="title"
                  v-model="loanApplication.name"
                  class="form-control"
                  rules="required:name"
                  :validateOnInput="true"
                  type="text"
                  placeholder="Name"
                  autocapitalize="false"
                />
                <ErrorMessage name="title" class="validation-msg" />
              </div>
              <!-- <div class="col-lg-12 mb-4">
                <label>Email </label>
                <Field name="email" class="form-control" rules="email" :validateOnInput="true"
                    v-model="loanApplication.email" type="text" placeholder="Email"
                    autocapitalize="false" />
                <ErrorMessage name="email" class="validation-msg" />
            </div> -->
              <div class="col-lg-12 mb-4">
                <label>Mobile Number <span class="text-danger">*</span> </label>
                <Field
                  name="mobile_number"
                  class="form-control"
                  rules="required|phone|numeric"
                  :validateOnInput="true"
                  v-model="loanApplication.mobile_number"
                  type="text"
                  placeholder="Mobile Number"
                  autocapitalize="false"
                />
                <ErrorMessage name="mobile_number" class="validation-msg" />
              </div>
              <!-- <div class="col-lg-12 mb-4">
                <label>Mobile Number <span class="text-danger">*</span> </label>
                <Field
                  name="mobile_number"
                  class="form-control"
                  rules="required|phone|numeric"
                  :validateOnInput="true"
                  v-model="loanApplication.mobile_number"
                  type="text"
                  placeholder="Mobile Number"
                  autocapitalize="false"
                />
                <ErrorMessage name="mobile_number" class="validation-msg" />
              </div> -->

              <div class="col-lg-12 mb-4">
                <label>Alt Mobile Number 1 </label>
                <Field
                  name="alt_mobile_number_1"
                  class="form-control"
                  id="alt_mobile_number_1"
                  rules="phone|numeric"
                  :validateOnInput="true"
                  v-model="loanApplication.alt_mobile_number_1"
                  type="text"
                  placeholder="Alt Mobile Number 1"
                  autocapitalize="false"
                />
                <ErrorMessage name="alt_mobile_number_1" class="validation-msg" />
              </div>
              <div class="col-lg-4 col-sm-12 pt-2">
                <label>Alt Mobile Number 2 </label>
                <Field
                  name="alt_mobile_number_2"
                  class="form-control"
                  id="alt_mobile_number_2"
                  rules="phone|numeric"
                  :validateOnInput="true"
                  v-model="loanApplication.alt_mobile_number_2"
                  type="text"
                  placeholder="Alt Mobile Number 2"
                  autocapitalize="false"
                />
                <ErrorMessage name="alt_mobile_number_2" class="validation-msg" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="col-12 text-center">
              <button
                type="button"
                @click="onCloseResetLoanDetail()"
                class="btn btn-brand-01 wb-save-btn mx-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-brand-01 wb-save-btn mx-2"
                id="save_basic_detail"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
  <ConfirmationModal @remove="onDeleteKycDocumentAPI" ref="deleteKycDocumentAll" />
  <vue-easy-lightbox
    :visible="visibleRef"
    :imgs="showableImage"
    :index="indexRef"
    @hide="onHide"
  ></vue-easy-lightbox>
</template>
<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
<script>
import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js"
import "vue-easy-lightbox/external-css/vue-easy-lightbox.css"
import {ref} from "vue"
import Multiselect from "@vueform/multiselect"
import "@vueform/multiselect/themes/default.css"
import {Form, Field, ErrorMessage} from "vee-validate"
import ConfirmationModal from "@/components/ConfirmationModal.vue"
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import moment from "moment"
export default {
  name: "AddEditLoanApplication",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    VueEasyLightbox,
    ConfirmationModal,
    VueDatePicker,
  },
  data() {
    return {
      id: this.$route.params.id,
      access: {
        can_read: 1,
        can_create: 1,
        can_update: 1,
        can_print: 1,
        can_delete: 1,
      },
      loanApplication: {
        name: "",
        email: "",
        mobile_number: "",
        loan_type_id: "",
        emi_interest: "",
        branch_id: "",
        loan_amount: 0,
        application_id: "",
      },
      user: {},
      branch_ids: [],
      documentType: [],
      loanDocument: [],
      documents: [],
      statusOption: [
        {
          title: "Active",
          value: "ACTIVE",
        },
        {
          title: "Inactive",
          value: "INACTIVE",
        },
      ],
      loan_title: "",
      fileName: "",
      kycDcoument: "",
      narration: "",
      reason_type: "",
      application_status: "",
      guarantorList: [],
      checklist: [],
      url: "",
      title: "",
      select_all: 0,
      loan: [],
      stateList: [],
      status_url: "",
      documentList: [],
      document_narration: "",
      cycle_start_date: "",
      disbursed_at: "",
      officerIds: [],
      addresses: {
        has_owned: 0,
        address: "",
        city: "",
        state_id: "",
        id: "",
        pincode: "",
      },
      addressType: "",
      homeAdresses: {
        has_owned: 0,
        address: "",
        city: "",
        state_id: "",
        id: "",
        pincode: "",
      },
      officeAddresses: {
        has_owned: 0,
        address: "",
        city: "",
        state_id: "",
        id: "",
        pincode: "",
      },
      residentialAddresses: {
        has_owned: 0,
        address: "",
        city: "",
        state_id: "",
        id: "",
        pincode: "",
      },
      officers: [],
      officer_uuid: "",
      maps: null,
      client_status: "",
      addressId: "",
      showableImage: "",
      visibleRef: ref(false),
      indexRef: ref(0),
      loanTypeList: [],
      loan_type_id: "",
      kycDocumentList: [],
      kycDocuments: {
        doc_id: "",
        doc_no: "",
        issue_date: "",
        expiry_date: "",
      },
      documentTitle: "",
      kycDocumentFile: "",
      kycFileName: "",
      has_additional_info: "",
      is_optional: "",
      kycLoanDocuments: [],
      downloadKycDocument: null,
      kycDocumentType: "ADD",
      kycDocummentUuid: "",
      requestedLoanAmont: "",
      sanctionAmount: "",
      sanctionNarration: "",
      requested_sanction_amount: "",
      requested_disbursed_amount: "",
      loan_application_branch_id: "",
      disable: true,
      has_cycle_date_changed: 0,
      change_loan_type: null,
      has_change_narration: false,
      change_narration: "",
      userType: null,
      running_loan_count: 0,
      renew_application_id: null,
      loanApplications: [],
      delete_id: "",
      user_uuid: "",
      sanction_notes: null,
      cycle_date: "",
      disbursement_date: "",
      max_approved_amount: 0,
    }
  },

  computed: {
    branchList() {
      return this.$storeService.getters.getBranches
    },
  },
  mounted() {
    this.userType = localStorage.getItem("user_type")
    if (this.id) {
      this.getLOanApplicationInfo()
      this.onGetStateList()
      this.onGetKycDocument()
    }
  },
  methods: {
    onDeleteKycDocument(item) {
      this.delete_id = item.id
      this.$refs.deleteKycDocumentAll.showModal(
        "Confirmation",
        "Are you sure you want to delete?",
        item
      )
    },
    onDeleteKycDocumentAPI() {
      var method = "DELETE"
      this.$api
        .webRequest({
          _method: method,
          _action: "loan/" + this.id + "/kyc-document/" + this.delete_id,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteKycDocumentAll.closeModal()
            this.getLOanApplicationInfo()
            this.$toast.success(res.message, {
              position: "top-right",
            })
          }
        })
        .catch((e) => {
          this.$refs.deleteKycDocumentAll.removeLoader()
          this.$toast.error(e.message, {
            position: "top-right",
          })
        })
    },
    changeLoanType(e) {
      if (e.target.checked) {
        this.change_loan_type = 1
        this.has_change_narration = true
      } else {
        this.change_loan_type = 0
        this.has_change_narration = false
      }
    },
    changeCycleDate(e) {
      var value = e.target.checked
      if (value == true) {
        this.disable = false
        this.has_cycle_date_changed = 1
      } else {
        this.disable = true
        this.has_cycle_date_changed = 0
      }
    },
    editGuarantor(item) {
      this.$router.push("/edit-guarantor/" + this.id + "/" + item.uuid)
    },
    OpenMapModel(item) {
      this.maps = item
      window.$("#openGooglemap").modal("show")
    },
    closeMapModal() {
      this.maps = null
      window.$("#openGooglemap").modal("hide")
    },
    openReAssignOfficer() {
      window.$("#reassignVerificationOfficer").modal("show")
    },
    onCloseReAssignOfficer() {
      window.$("#reassignVerificationOfficer").modal("hide")
    },
    openReNewLoan() {
      this.onGetLoanApplicationList()
      window.$("#reNewLoan").modal("show")
    },
    onCloseReNewLoan() {
      window.$("#reNewLoan").modal("hide")
    },
    openRemoveReNewLoan() {
      window.$("#reRemoveNewLoan").modal("show")
    },
    onCloseResetLoanDetail() {
      window.$("#ResetLoanDetail").modal("hide")
    },
    openResetLoanDetail() {
      window.$("#ResetLoanDetail").modal("show")
    },
    onCloseRemoveReNewLoan() {
      window.$("#reRemoveNewLoan").modal("hide")
    },
    openReAssignRejectedApplication() {
      window.$("#updateRejectedStatus").modal("show")
    },
    closeUpdatedRejectedModal() {
      window.$("#updateRejectedStatus").modal("hide")
    },
    openAssignOfficer() {
      window.$("#assignVerificationOfficer").modal("show")
      if (this.loanApplication.verification_officer !== null) {
        this.officer_uuid = this.loanApplication.verification_officer.uuid
      }
    },
    onCloseAssignOfficer() {
      window.$("#assignVerificationOfficer").modal("hide")
      this.officer_uuid = null
    },
    onRejectModal() {
      window.$("#rejectApplication").modal("show")
      this.officer_uuid = this.loanApplication.verification_officer.uuid
    },
    onCloseRejectModal() {
      window.$("#rejectApplication").modal("hide")
      this.officer_uuid = null
    },
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm()
    },
    onUpdateAddresses() {
      console.log("Adresss Object :", this.addresses)
      if (this.addressType == "HOME_ADDRESS") {
        this.addresses.type = "PERMANENT"
      }
      if (this.addressType == "OFFICE_ADDRESS") {
        this.addresses.type = "OFFICE"
      }
      if (this.addressType == "RESIDENTIAL_ADDRESS") {
        this.addresses.type = "RESIDENTIAL"
      }
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "loan/" + this.id + "/address/" + this.addressId,
          _buttonId: "address-btn",
          _body: this.addresses,
        })
        .then((res) => {
          this.onClearForm("addressUpdate")
          this.getLOanApplicationInfo()
          this.onCloseAddress()
          this.$toast.success(res.message, {position: "top-right"})
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    onUpdateAddress(type) {
      this.addressType = type

      if (type == "HOME_ADDRESS") {
        console.log("address ID  HOME_ADDRESS: ", this.homeAdresses)
        this.addresses = this.homeAdresses
        this.addressId = this.homeAdresses.id
      }
      if (type == "OFFICE_ADDRESS") {
        console.log("address ID OFFICE_ADDRESS : ", this.officeAddresses)
        this.addresses = this.officeAddresses
        this.addressId = this.officeAddresses.id
      }
      if (type == "RESIDENTIAL_ADDRESS") {
        console.log("address ID RESIDENTIAL_ADDRESS : ", this.residentialAddresses)
        this.addresses = this.residentialAddresses
        this.addressId = this.residentialAddresses.id
      }

      window.$("#viewAddressModal").modal("show")
    },
    onCloseAddress() {
      window.$("#viewAddressModal").modal("hide")
      this.addressType = null
      this.addresses = {
        has_owned: 0,
        address: "",
        city: "",
        state_id: "",
        id: "",
        pincode: "",
      }
      // this.homeAdresses = {};
      // this.officeAddresses = {};
      this.addressId = null
    },
    updateReasignApplication() {
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "loans/" + this.id + "/reset",
          _buttonId: "status-btn",
          _body: {},
        })
        .then((res) => {
          this.closeUpdatedRejectedModal()
          this.$toast.success(res.message, {position: "top-right"})
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },

    getDocumentList(id) {
      this.documentList = []
      var method = "GET"
      this.$api
        .webRequest({
          _method: method,
          _action: "select/loan/" + id + "/documents",
        })
        .then((res) => {
          this.documentList = res.list
          var obj = {id: 0, title: "Other"}
          this.documentList.push(obj)
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    checkPermission(e, obj) {
      if (e.target.checked) {
        var abc = {
          id: obj.id,
          has_checked: 1,
        }
        this.loan.push(abc)
        obj.has_checked = 1
      } else {
        for (var i = 0; i < this.loan.length; i++) {
          if (obj.id == this.loan[i].id) {
            obj.has_checked = 0
            this.loan.splice(i, 1)
          }
        }
      }

      for (var k = 0; k < this.checklist.length; k++) {
        this.select_all = 1
        if (!this.checklist[k].has_checked) {
          this.select_all = 0
          break
        }
      }
      console.log(this.loan)
    },
    onUplaodDocument() {
      window.$("#uploadLoanDocument").modal("show")
    },
    closeModal() {
      window.$("#uploadLoanDocument").modal("hide")
      this.onClearForm("onUploadLoanDocument")
    },
    onNarrtion() {
      window.$("#openRejectNarration").modal("show")
    },
    closeNarrationModal() {
      this.narration = null
      this.reason_type = null
      window.$("#openRejectNarration").modal("hide")
      window.$("#statusModal").modal("hide")

      if (this.reason_type == "REJECTED") {
        this.onClearForm("onAddRejectNarration")
      }
    },
    onCheckUserTier() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/loan-type",
          _body: {
            email: this.loanApplication.email,
            mobile_number: this.loanApplication.mobile_number,
          },
        })
        .then((res) => {
          this.loanTypeList = []
          for (var i = 0; i < res.list.length; i++) {
            var obj = {
              title:
                res.list[i].title +
                " (" +
                this.$helperService.getTitleCase(res.list[i].type) +
                ")" +
                " - " +
                this.$helperService.getTitleCase(res.list[i].level),
              value: res.list[i].id,
            }
            this.loanTypeList.push(obj)
          }
        })
        .catch(() => {})
    },
    getLOanApplicationInfo() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "loan/" + this.id,
        })
        .then((res) => {
          this.max_approved_amount = res?.info?.max_approved_amount
            ? res?.info?.max_approved_amount
            : 0
          this.access = res?.access
          this.user_uuid = res?.info?.user?.uuid
          this.sanction_notes = res?.info?.sanction_notes
          this.running_loan_count = res?.info?.running_loan_count
          if (res?.info?.sanction_amount > 0) {
            this.requested_sanction_amount = res?.info?.sanction_amount
          } else {
            this.requested_sanction_amount = res?.info?.loan_amount
          }
          console.log("requested_sanction_amount", this.requested_sanction_amount)
          this.documents = []
          this.kycLoanDocuments = res?.info?.kyc_documents
          this.checklist = []
          this.user = res?.info?.user
          if (
            res?.info?.status == "DISBURSED" &&
            res?.info?.has_cycle_date_changed == 1
          ) {
            this.cycle_date = res?.info?.cycle_start_date
            this.disbursement_date = res?.info?.disbursed_at
            this.disbursed_at = res?.info?.disbursed_at
            this.cycle_start_date = res?.info?.cycle_start_date
          }
          this.loanApplication.application_id = res?.info?.application_id
          this.loanApplication.name = res?.info?.applicant_name
          this.loanApplication.email = res?.info?.email
          this.loanApplication.mobile_number = res?.info?.mobile_number;
          this.loanApplication.alt_mobile_number_1 = res?.info?.alt_mobile_number_1;
          this.loanApplication.alt_mobile_number_2 = res?.info?.alt_mobile_number_2;
       
          this.loanApplication.narration = res?.info?.narration
          this.loanApplication.verification_officer = res?.info?.verification_officer
          this.loanApplication.user = res?.info?.user
          if (res.info.branch) {
            this.loanApplication.branch_id = res?.info?.branch?.id
          }
          this.user_uuid = res?.info?.user?.uuid

          this.change_narration = res?.info?.loan_type_change_request
          if (this.userType === "SUPER_ADMIN" || this.userType === "PARTNER") {
            this.change_loan_type = 0
          } else {
            this.change_loan_type = res?.info?.has_loan_type_changed
          }

          this.has_change_narration =
            res?.info?.has_loan_type_changed === 1 ? true : false

          if (res.info.checklists) {
            this.checkList = res?.info?.checklists
          }
          this.loanApplication.loan_type = res?.info?.loan_type_id
          this.onCheckUserTier(res?.info?.loan_type_id)
          this.loan_type_id = res?.info?.loan_type_id
          if (res.info.loan_type) {
            this.loanApplication.level =
              res.info.loan_type.title +
              " (" +
              this.$helperService.getTitleCase(res.info.loan_type.type) +
              ")"
            this.loanApplication.level_title = res?.info?.loan_type?.level
          }
          if (res.info.home_address != null) {
            this.homeAdresses = res.info.home_address
            this.homeAdresses.id = res.info.home_address.id
            this.homeAdresses.pincode = res.info.home_address.pincode
            this.homeAdresses.address = res?.info?.home_address?.address
              ? res?.info?.home_address?.address
              : ""
            this.homeAdresses.city = res?.info?.home_address?.city
              ? res?.info?.home_address?.city
              : ""
            this.homeAdresses.state_id = res?.info?.home_address?.state_id
              ? res?.info?.home_address?.state_id
              : ""
          }
          if (res.info.office_address != null) {
            this.officeAddresses = res.info.office_address
            this.officeAddresses.id = res.info.office_address.id
            this.officeAddresses.pincode = res.info.office_address.pincode
            this.officeAddresses.address = res?.info?.office_address?.address
              ? res?.info?.office_address?.address
              : ""
            this.officeAddresses.city = res?.info?.office_address?.city
              ? res?.info?.office_address?.city
              : ""
            this.officeAddresses.state_id = res?.info?.office_address?.state_id
              ? res?.info?.office_address?.state_id
              : ""
          }
          if (res.info.residential_address != null) {
            this.residentialAddresses = res.info.residential_address
            this.residentialAddresses.id = res.info.residential_address.id
            this.residentialAddresses.pincode = res.info.residential_address.pincode
            this.residentialAddresses.address = res?.info?.residential_address?.address
              ? res?.info?.residential_address?.address
              : ""
            this.residentialAddresses.city = res?.info?.residential_address?.city
              ? res?.info?.residential_address?.city
              : ""
            this.residentialAddresses.state_id = res?.info?.residential_address?.state_id
              ? res?.info?.residential_address?.state_id
              : ""
          }
          this.requestedLoanAmont = res?.info?.loan_amount
          this.sanctionAmount = res?.info?.sanction_amount
          this.sanctionNarration = res?.info?.sanction_narration
          this.client_status = res?.info?.client_status

          this.documents = res?.info?.documents
          this.checklist = res?.info?.checklists
          this.loanDocument = res?.info?.documents
          this.application_status = res.info.status
          this.has_cycle_date_changed = res.info.has_cycle_date_changed
          this.guarantorList = res?.info?.guarantors
          this.loanApplication.home_address = res?.info?.home_address
          this.loanApplication.office_address = res?.info?.office_address
          this.loanApplication.residential_address = res?.info?.residential_address
          this.loanApplication.applicant_photo = res.info.applicant_photo
          this.loanApplication.applicant_video = res.info.applicant_video
          this.loanApplication.applicant_thumb_img = res.info.applicant_thumb_img
          this.loanApplication.loan_amount = res?.info?.loan_amount
          this.loanApplication.disbursed_loan_amount = res?.info?.disbursed_loan_amount
          // this.loanApplication.parent_loan = res?.info?.parent_loan
          //   ? res?.info?.parent_loan
          //   : res?.info?.renew_loan
          this.loanApplication.rejected_by = res?.info?.rejected_by
          this.requested_disbursed_amount = res?.info?.disbursed_loan_amount

          if (this.checklist.length == 0) {
            console.log("No checklist")
            this.select_all = 1
          } else {
            for (var k = 0; k < this.checklist.length; k++) {
              this.select_all = 1
              if (!this.checklist[k].has_checked) {
                this.select_all = 0
                break
              }
            }
          }
          this.onOfficerList(res?.info?.branch?.id)
          this.getDocumentList(res?.info?.loan_type_id)
          this.assignAutoCycleDate()
        })
        .catch(() => {})
    },
    saveCheckList(type) {
      this.$api
        .webRequest({
          _method: "POST",
          _action: "loan/" + this.id + "/checklist",
          _body: {list: this.loan},
          _buttonId: type == "SAVE" ? "save-btn" : "status-btn",
        })
        .then((res) => {
          if (type == "NEXT") {
            window.$("#statusModal").modal("show")
          } else {
            this.getLOanApplicationInfo()
            this.$toast.success(res.message, {position: "top-right"})
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    save() {
      var method = "POST"
      var action = "/loan"
      if (this.id) {
        method = "PUT"
        action = "loan/" + this.id
      }
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: this.loanApplication,
          _buttonId: "save-btn",
        })
        .then((res) => {
          this.onClearForm("addEditLoanApplication")
          this.$toast.success(res.message, {position: "top-right"})
          this.$router.go(-1)
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },

    onUpdateReassign() {
      var method = "PUT"
      var action = "loans/" + this.id + "/assign"
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: {},
          _buttonId: "save-btn",
        })
        .then((res) => {
          this.onCloseReAssignOfficer()
          this.getLOanApplicationInfo()
          this.$toast.success(res.message, {position: "top-right"})
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    onDeleteRenewApplication() {
      var method = "POST"
      var action = "loan/" + this.id + "/renew/remove"
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: {},
          _buttonId: "delete-renew-btn",
        })
        .then((res) => {
          this.onCloseRemoveReNewLoan()
          this.getLOanApplicationInfo()
          this.$toast.success(res.message, {position: "top-right"})
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    onUpdateVerificationOfficer() {
      var data = {
        user_id: this.officer_uuid,
        loan_ids: [this.id],
        branch_id: this.loan_application_branch_id,
      }
      var method = "POST"
      var action = "loans/assign/verification-officer"
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: data,
          _buttonId: "save-btn",
        })
        .then((res) => {
          this.onCloseAssignOfficer()
          this.getLOanApplicationInfo()
          this.$toast.success(res.message, {position: "top-right"})
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    onReLoanApplication() {
      var method = "POST"
      var action = "loan/" + this.id + "/renew"
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: {renew_application_id: this.renew_application_id},
          _buttonId: "renew-loan-btn",
        })
        .then((res) => {
          this.onCloseReNewLoan()
          this.getLOanApplicationInfo()
          this.$toast.success(res.message, {position: "top-right"})
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    onUploadLoanDocument() {
      this.$api
        .uploadImageAPI({
          _action: "loan/" + this.id + "/upload/document",
          _key: "image",
          _buttonId: "document-upload",
          _file: this.kycDcoument,
          _body: {
            title: this.loan_title == "Other" ? this.document_narration : this.loan_title,
          },
        })
        .then((res) => {
          this.$toast.success(res.message, {position: "top-right"})
          this.closeModal()
          this.getLOanApplicationInfo()
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },

    onUpdateStatus(status, url) {
      console.log(status, url);
      this.status_url = url
      this.reason_type = status

      if(status == "REJECTED"){
        window.$("#statusModal").modal("hide")
        window.$("#rejectApplication").modal("hide")
        window.$("#openRejectNarration").modal("show")
        return false
      }

      if (this.application_status != "PENDING") {
        if (this.kycLoanDocuments.length > 0) {
          var requiredDoc = this.kycDocumentList.filter((obj) => {
            if (obj.is_optional === 0) {
              return obj.id
            }
          })

          var submittedDoc = this.kycLoanDocuments.filter((obj) => {
            if (obj.doc.is_optional === 0) {
              return obj.doc.id
            }
          })
          //Check Unique Documents

          if (requiredDoc.length != submittedDoc.length) {
            this.$toast.error("Please upload all required documents for KYC verification.", {
              position: "top-right",
            })
            return false
          } else {
            window.$("#openRejectNarration").modal("show");
            return true;
          }
        } else {
          this.$toast.error("Please complete KYC details.", {
            position: "top-right",
          })
          return false
        }
      } else {
        window.$("#openRejectNarration").modal("show");
        return true;
      }
    },
    setStatusUrl(url) {
      this.reason_type = "APPROVED"
      this.status_url = url
      this.onAddRejectNarration()
    },
    assignAutoCycleDate() {
      var date = new Date()
      setTimeout(() => {
        if (date.getDate() > 15) {
          let nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1)
          let lastDay = new Date(nextMonth - 1)
          this.cycle_start_date = lastDay.toISOString().split("T")[0]
        } else {
          let currentMonth = date.getMonth() + 1
          if (currentMonth < 10) {
            currentMonth = "0" + currentMonth
          }
          this.cycle_start_date = `${date.getFullYear()}-${currentMonth}-15`
          this.cycle_start_date = moment(this.cycle_start_date).format("YYYY-MM-DD")
        }
        console.log(this.cycle_start_date)
      }, 1000)
    },
    onAddRejectNarration() {
      var button_id = "confirmation-btn"
      this.$api
        .webRequest({
          _method: "PUT",
          _action: this.status_url,
          _buttonId: button_id,
          _body: {status: this.reason_type, narration: this.narration},
        })
        .then((res) => {
          this.onClearForm("onAddRejectNarration")

          var active_tab = localStorage.getItem("loan_application_active_tab")
          console.log(" active tab : ", active_tab)
          if (this.reason_type == "REJECTED") {
            localStorage.setItem("loan_application_active_tab", "REJECTED")
            this.$router.push("/loan_applications")
          }
          if (this.reason_type == "APPROVED") {
            if (active_tab == "PENDING") {
              localStorage.setItem(
                "loan_application_active_tab",
                "PENDING_FOR_VERIFICATION"
              )
              this.$router.push("/loan_applications")
            }
            if (active_tab == "PENDING_FOR_VERIFICATION") {
              localStorage.setItem("loan_application_active_tab", "DOCUMENT_VERIFIED")
              this.$router.push("/loan_applications")
            }
            if (active_tab == "DOCUMENT_VERIFIED") {
              localStorage.setItem(
                "loan_application_active_tab",
                "PENDING_FOR_FINAL_APPROVAL"
              )
              this.$router.push("/loan_applications")
            }

            if (active_tab == "PENDING_FOR_FINAL_APPROVAL") {
              localStorage.setItem("loan_application_active_tab", "FINAL_APPROVAL")
              this.$router.push("/loan_applications")
            }
          }
          this.closeNarrationModal()
          this.$toast.success(res.message, {position: "top-right"})
          this.getLOanApplicationInfo()
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    download(url, title) {
      this.url = url
      this.title = title
      window.$("#viewModal").modal("show")
    },
    selectFile(e) {
      e.stopImmediatePropagation()
      window.$("#selectFile").click()
    },
    onDocumentChange(e) {
      let files = e.target.files
      if (files.length > 0) {
        this.fileName = files[0].name
        this.kycDcoument = files[0]
      } else {
        this.fileName = null
        this.kycDcoument = null
      }
    },
    yyyymmdd(dateIn) {
      var yyyy = dateIn.getFullYear()
      var mm = dateIn.getMonth() + 1 // getMonth() is zero-based
      var dd = dateIn.getDate()
      return dd + "-" + mm + "-" + yyyy // Leading zeros for mm and dd
    },
    onApprove() {
      window.$("#onApprove").modal("show")
      // var date = new Date();
      // setTimeout(() => {
      //     this.disbursed_at = String(this.yyyymmdd(date));
      //     console.log(this.disbursed_at);
      // }, 1000);
    },
    approveCloseModal() {
      window.$("#onApprove").modal("hide")
      if (this.application_status != "DISBURSED") {
        this.onClearForm("disbursed")
        this.disbursed_at = ""
        this.cycle_start_date = ""
      }
      this.assignAutoCycleDate()
    },
    setActualDate(e) {
      console.log(this.disbursed_at)
      // var date = moment(this.disbursed_at).format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
      var date = new Date(e.target.value)
      // console.log(date,"date value is here",momentDate);
      this.cycle_start_date = ""

      setTimeout(() => {
        if (date.getDate() > 15) {
          let nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1)
          let lastDay = new Date(nextMonth - 1)
          this.cycle_start_date = lastDay.toISOString().split("T")[0]
        } else {
          let currentMonth = date.getMonth() + 1
          if (currentMonth < 10) {
            currentMonth = "0" + currentMonth
          }
          this.cycle_start_date = `${date.getFullYear()}-${currentMonth}-15`
          this.cycle_start_date = moment(this.cycle_start_date).format("YYYY-MM-DD")
        }
        console.log(this.cycle_start_date)
      }, 1000)
    },

    disbursed() {
      this.$api
        .webRequest({
          _method: "POST",
          _action: "loans/disbursed",
          _buttonId: "cycle-update",
          _body: {
            loans: [this.id],
            cycle_start_date: this.cycle_start_date,
            has_cycle_date_changed: this.has_cycle_date_changed,
            disbursed_at: this.disbursed_at,
          },
        })
        .then((res) => {
          this.$toast.success(res.message, {position: "top-right"})
          this.approveCloseModal()
          var active_tab = localStorage.getItem("loan_application_active_tab")
          console.log(" active tab : ", active_tab)
          if (active_tab == "FINAL_APPROVAL") {
            localStorage.setItem("loan_application_active_tab", "PENDING")
            this.$router.push("/loan_disbursed")
          } else {
            localStorage.setItem("loan_application_active_tab", "FINAL_APPROVAL")
            this.$router.push("/loan_applications")
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    onGetStateList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/states",
        })
        .then((res) => {
          this.stateList = res.list
        })
        .catch(() => {})
    },

    onOfficerList(id) {
      this.loan_application_branch_id = id
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/verification-officers",
          _body: {branch_id: id},
        })
        .then((res) => {
          this.officers = res.list
        })
        .catch(() => {})
    },
    onGetLoanApplicationList() {
      // this.loan_application_branch_id = id;
      this.$api
        .webRequest({
          _method: "GET",
          _action: "loan/" + this.id + "/running-loans",
        })
        .then((res) => {
          this.loanApplications = res.list
        })
        .catch(() => {})
    },
    openImage(image, index) {
      this.showableImage = image
      this.indexRef = index
      this.visibleRef = true
    },
    onHide() {
      this.visibleRef = false
      this.showableImage = ""
      this.indexRef = ""
    },
    downloadPdf(url, filename) {
      console.log(url, filename)
      var anchorElement = document.createElement("a")
      anchorElement.href = url
      anchorElement.download = filename
      anchorElement.target = "_blank"
      document.body.appendChild(anchorElement)
      console.log(anchorElement)
      anchorElement.click()
      document.body.removeChild(anchorElement)
    },
    saveLoanRequirement() {
      console.log("application status : ", this.application_status)
      var amount = 0
      if (
        this.application_status == "PENDING_FOR_VERIFICATION" ||
        this.application_status == "PENDING"
      ) {
        amount = this.requested_sanction_amount
      } else {
        amount = this.requested_disbursed_amount
      }
      this.$api
        .webRequest({
          _method: "PUT",
          _action: "loan/" + this.id + "/set",
          _body: {
            loan_amount: amount,
            loan_type_id: this.loan_type_id,
            loan_type_change_request: this.change_narration,
            has_loan_type_changed: this.change_loan_type,
            sanction_notes: this.sanction_notes,
          },
          _buttonId: "loan-application-btn",
        })
        .then((res) => {
          this.$toast.success(res.message, {position: "top-right"})
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    editKycDocument(item, type) {
      this.kycDocummentUuid = item?.uuid
      this.kycDocumentType = type
      this.kycDocuments = {
        doc_id: item?.doc?.uuid,
        doc_no: item.doc_no,
      }
      if (item?.issue_date != null) {
        this.kycDocuments.issue_date = item?.issue_date
      }
      if (item?.expiry_date != null) {
        this.kycDocuments.expiry_date = item?.expiry_date
      }
      this.kycDocumentList.forEach((obj) => {
          if (obj.uuid == item?.doc?.uuid) {
            this.documentTitle = obj.code
          }
        });
      this.downloadKycDocument = item?.doc_img
      this.kycDcoumentFile = ""
      console.log("edit kyc details : ", item)
      window.$("#uploadKycDocs").modal("show")
    },
    onUplaodKycDocument() {
      window.$("#uploadKycDocs").modal("show")
    },
    onCloseUplaodKycModal() {
      window.$("#uploadKycDocs").modal("hide")
      this.kycDocuments = {
        doc_id: "",
        doc_number: "",
        issue_date: "",
        expiry_date: "",
      }
      this.kycDocumentFile = ""
      this.kycFileName = null
      this.downloadKycDocument = null
      this.kycDocummentUuid = ""
    },
    afterValueChange(e) {
      console.log("Change Event : ", e)
      if (this.kycDocumentList.length > 0) {
        this.kycDocumentList.forEach((obj) => {
          if (obj.uuid == e) {
            console.log("Selected Obj", obj)
            this.documentTitle = obj.title
            this.has_additional_info = obj.has_additional_info
            this.is_optional = obj.is_optional
            console.log(
              "the value is ",
              obj.title,
              obj.has_additional_info,
              obj.is_optional
            )
          }
        })
      }
    },
    onGetKycDocument() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "kyc-documents",
        })
        .then((res) => {
          this.kycDocumentList = res.list
        })
        .catch(() => {})
    },
    onUploadkycDetails() {
      if (this.kycDocuments.issue_date) {
        this.kycDocuments.issue_date = moment(
          String(this.kycDocuments.issue_date)
        ).format("Y-MM-DD")
      }
      if (this.kycDocuments.expiry_date) {
        this.kycDocuments.expiry_date = moment(
          String(this.kycDocuments.expiry_date)
        ).format("Y-MM-DD")
      }

      // if (this.kycDocuments) {
      //     console.log("the value of the kyc document is :", this.kycDocuments);
      //     return true;
      // }
      var url = ""
      if (this.kycDocumentType == "EDIT") {
        url = "loan/" + this.id + "/kyc-document/" + this.kycDocummentUuid
      } else {
        url = "loan/" + this.id + "/kyc-document"
      }
      this.$api
        .webRequest({
          _method: "POST",
          _action: url,
          _body: this.kycDocuments,
          _buttonId: "loan-kyc-document",
        })
        .then((res) => {
          this.$toast.success(res.message, {position: "top-right"})
          if (typeof this.kycDcoumentFile == "object") {
            this.onUploadKyCFiles(this.kycDcoumentFile, res?.id)
          } else {
            this.onCloseUplaodKycModal()
            this.getLOanApplicationInfo()
          }
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },

    onUploadKyCFiles(file, uuid) {
      this.$api
        .uploadImageAPI({
          _action: "loan/" + this.id + "/kyc-document/" + uuid + "/upload",
          _key: "file",
          _buttonId: "loan-kyc-document",
          _file: this.kycDcoumentFile,
        })
        .then(() => {
          // this.$toast.success(res.message, { position: "top-right" });
          this.getLOanApplicationInfo()
          this.onCloseUplaodKycModal()
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    selectKycFile(e) {
      e.stopImmediatePropagation()
      window.$("#selectKycFile").click()
    },
    onKycDocumentChange(e) {
      let files = e.target.files
      if (files.length > 0) {
        this.kycFileName = files[0].name
        this.kycDcoumentFile = files[0]
        console.log("kyc file:", this.kycDcoumentFile)
      } else {
        this.kycFileName = null
        this.kycDocumentFile = null
      }
    },
    OnReSetLoanDetails() {
      let obj = {
        applicant_name: this.loanApplication.name,
        mobile_number: this.loanApplication.mobile_number,
        alt_mobile_number_1: this.loanApplication.alt_mobile_number_1,
        alt_mobile_number_2: this.loanApplication.alt_mobile_number_2,
      }
      this.$api
        .webRequest({
          _method: "PUT",
          _action: `loan/${this.id}/basic`,
          _body: obj,
          _buttonId: "save_basic_detail",
        })
        .then((res) => {
          this.$toast.success(res.message, {position: "top-right"})
          this.onCloseResetLoanDetail()
        })
        .catch((e) => {
          this.$toast.error(e.message, {position: "top-right"})
        })
    },
    addGuarantor() {
      localStorage.setItem("guarantor-tab", "guarantor");
      this.$router.push("/add-guarantor/" + this.id);
    },
  },
}
</script>
