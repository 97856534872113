<template>
  <div class="content-body">
    <div class="worker_serch_warp">
      <div class="row d-flex justify-content-between">
        <div class="col-md-2 pr-2">
          <h2 class="content-title">
            <a @click="$router.go(-1)" style="cursor: pointer"
              ><i class="fas fa-angle-left mr-2"></i
              ><span v-if="!this.id">Add User</span>
              <span v-else>Edit User</span>
            </a>
          </h2>
        </div>
      </div>
    </div>
    <div class="component-section no-code">
      <div class="row-sm wb-row-gap">
        <Form
          @submit="save"
          class="columns column is-multiline is-12"
          data-vv-scope="frmAddUser"
          ref="addEditUser"
        >
          <div class="col-lg-12">
            <div class="card rounded-5">
              <div class="card-body p-3 p-lg-3">
                <div class="row">
                  <div class="col-lg-4 mb-4">
                    <label> Name <span class="text-danger">*</span> </label>
                    <Field
                      name="name"
                      v-model="user.name"
                      class="form-control"
                      rules="required"
                      :validateOnInput="true"
                      type="text"
                      autoComplete="off"
                      placeholder="Name"
                      autocapitalize="false"
                    />

                    <ErrorMessage name="name" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label> Email <span class="text-danger"></span> </label>
                    <Field
                      name="email"
                      class="form-control"
                      rules="email"
                      au
                      :validateOnInput="true"
                      v-model="user.email"
                      type="email"  autoComplete="off"
                      placeholder="Email"
                      autocapitalize="false"
                    />
                    <ErrorMessage name="email" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label>
                      Mobile Number
                      <span class="text-danger">*</span>
                    </label>

                    <Field
                      name="mobile_number"
                      class="form-control"
                      id="mobile_number"
                      rules="required|phone|numeric"
                      :validateOnInput="true"
                      v-model="user.mobile_number"
                      type="text"
                      autoComplete="off"
                      placeholder="Mobile number"
                      autocapitalize="false"
                    />
                    <ErrorMessage name="mobile_number" class="validation-msg" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 mb-4">
                    <label>User Type<span class="text-danger">*</span></label>
                    <Field
                      v-slot="{ field }"
                      name="user_type"
                      rules="required:user type,true"
                      :validateOnInput="true"
                      v-model="user_type"
                    >
                      <Multiselect
                        v-bind="field"
                        ref="multiselect"
                        trackBy="type"
                        rules="required"
                        label="title"
                        valueProp="type"
                        autoComplete="off"
                        placeholder="Select user type"
                        class="form-control"
                        v-model="user_type"
                        :options="userType"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="user_type" class="validation-msg" />
                  </div>
                  <div class="col-md-4 mb-4">
                    <label>Joining Date</label>
                    <Field
                      name="joining_date"
                      class="form-control"
                      v-slot="{ date }"
                      id="joining_date"
                      v-model="user.joining_date"
                    >
                      <VueDatePicker
                        v-bind="date"
                        placeholder="Joining Date"
                        style="height: 46px; !important"
                        v-model="user.joining_date"
                        :type="boolean"
                        :format="'dd/MM/yyyy'"
                        :default="true"
                        :enable-time-picker="false"
                      >
                      </VueDatePicker>
                    </Field>
                    <ErrorMessage name="joining_date" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label> Basic Salary </label>
                    <Field
                      name="salary"
                      class="form-control"
                      id="salary"
                      rules="numeric"
                      :validateOnInput="true"
                      v-model="user.salary"
                      step="any"
                      placeholder="Basic Salary"
                      autocapitalize="false"
                    />
                    <ErrorMessage name="salary" class="validation-msg" />
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-lg-4 mb-4"
                    v-if="
                      user_type == 'BRANCH_STAFF' ||
                      user_type == 'ENQUIRY_USER' ||
                      user_type == 'DOCUMENT_VERIFY_USER' ||
                      user_type == 'COLLECTION_MANAGER' ||
                      user_type == 'VERIFICATION_MANAGER'
                    "
                  >
                    <label> Branch <span class="text-danger">*</span> </label>
                    <Field
                      v-slot="{ field }"
                      name="branch_id"
                      rules="required:branch,true"
                      :validateOnInput="true"
                      v-model="user.branch_id"
                    >
                      <Multiselect
                        v-bind="field"
                        ref="branch_id_multiselect"
                        trackBy="name"
                        rules="required"
                        @select="getUserRoleBasedOnType('OUTER_BRANCH')"
                        label="name"
                        valueProp="branch_id"
                        placeholder="Select branch"
                        class="form-control"
                        v-model="user.branch_id"
                        :options="branchList"
                        :close-on-select="true"
                        :createOption="trFue"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="branch_id" class="validation-msg" />
                  </div>
                  <div
                    class="col-lg-4 mb-4"
                    v-if="user_type == 'COLLECTION_MANAGER'"
                  >
                    <label>
                      Task Force <span class="text-danger">*</span>
                    </label>
                    <Field
                      v-slot="{ field }"
                      name="task_force"
                      rules="required:task force"
                      :validateOnInput="true"
                      v-model="user.task_force_level"
                    >
                      <Multiselect
                        v-bind="field"
                        ref="level_multiselect"
                        trackBy="title"
                        label="title"
                        valueProp="value"
                        placeholder="Select Task Force"
                        class="form-control"
                        v-model="user.task_force_level"
                        :options="taskforces"
                        :close-on-select="true"
                        :createOption="true"
                        :searchable="true"
                      />
                    </Field>
                    <ErrorMessage name="task_force" class="validation-msg" />
                  </div>
                  <div
                    class="col-lg-4 mb-4"
                    v-if="
                      user_type == 'BRANCH_STAFF' ||
                      user_type == 'ENQUIRY_USER' ||
                      user_type == 'DOCUMENT_VERIFY_USER'
                    "
                  >
                    <label> Role <span class="text-danger">*</span> </label>
                    <Field
                      v-slot="{ field }"
                      name="role_id"
                      rules="required:role,true"
                      :validateOnInput="true"
                      v-model="user.role_id"
                    >
                      <Multiselect
                        v-bind="field"
                        ref="role_id_multiselect"
                        trackBy="name"
                        rules="required"
                        label="role_name"
                        valueProp="id"
                        placeholder="Select user role"
                        class="form-control"
                        v-model="user.role_id"
                        :options="roles"
                        :searchable="true"
                        :createOption="true"
                      />
                    </Field>
                    <ErrorMessage name="role_id" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4">
                    <label> Status <span class="text-danger">*</span> </label>
                    <Field
                      v-slot="{ field }"
                      name="status"
                      rules="required:status,true"
                      :validateOnInput="true"
                      v-model="user.status"
                    >
                      <select
                        v-bind="field"
                        class="form-control"
                        v-model="user.status"
                      >
                        <option value="ACTIVE">Active</option>
                        <option value="INACTIVE">Inactive</option>
                      </select>
                    </Field>
                    <ErrorMessage name="role_id" class="validation-msg" />
                  </div>
                  <div class="col-lg-4 mb-4" v-if="!this.id">
                    <div class="form-group">
                      <label for="password"
                        >Password <span class="text-danger">*</span></label
                      >
                      <div class="login-field" id="show_hide_password">
                        <Field
                          name="password"
                          type="password"
                          id="password"
                          v-model="user.password"
                          class="form-control"
                          rules="required|password"
                          :validateOnInput="true"
                          placeholder="Password"
                          autocapitalize="false"
                        />
                        <i
                          id="button"
                          alt=""
                          class="show-icon fa fa-eye-slash"
                          style="top: 15px !important"
                        ></i>
                      </div>
                      <ErrorMessage name="password" class="validation-msg" />
                    </div>
                  </div>
                  <div class="col-lg-4 mb-4" v-if="!this.id">
                    <div class="form-group">
                      <label for="conf_password"
                        >Confirm Password
                        <span class="text-danger">*</span></label
                      >
                      <div class="login-field" id="show_hide_password_2">
                        <Field
                          name="confirm_password"
                          type="password"
                          id="conf_password"
                          v-model="user.confirm_password"
                          v-on:keydown.space="$event.preventDefault()"
                          rules="required:confirm password|confirmed:@password"
                          class="form-control"
                          :validateOnInput="true"
                          placeholder="Confirm Password"
                          autocomplete="off"
                          autocapitalize="false"
                        />
                        <i
                          id="button"
                          alt=""
                          class="show-icon fa fa-eye-slash"
                          style="top: 15px !important"
                        ></i>
                      </div>
                      <ErrorMessage
                        name="confirm_password"
                        class="validation-msg"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6 mb-4">
                    <label> Description </label>
                      <Field
                        v-slot="{ field }"
                        name="description"
                        v-model="user.narration"
                        type="text"
                        autocapitalize="false"
                      >
                        <textarea
                          v-bind="field"
                          placeholder="Description(Optional)"
                          cols="5"
                          rows="5"
                          class="form-control"
                          v-model="user.narration"
                        ></textarea>
                      </Field>
                  </div>
                </div>

                <h4
                  class="pt-3"
                  style="border-bottom: 1px solid #cccccc; line-height: 2"
                >
                  Kyc Document
                </h4>
                <div class="row pt-1">
                  <div class="col-lg-6 mb-4">
                    <div class="form-group mb-0">
                      <label for="Aadhaar_no"
                        >Aadhaar No <span class="text-danger">*</span></label
                      >
                      <div class="login-field">
                        <Field
                          name="Aadhaar_no"
                          type="text"
                          autoComplete="off"
                          id="Aadhaar_no"
                          v-model="user.aadhaar_no"
                          class="form-control"
                          :rules="'required:aadhaar card,true|aadhaar'"
                          :validateOnInput="true"
                          :placeholder="'Aadhaar'"
                          autocapitalize="false"
                        />
                      </div>
                      <ErrorMessage name="Aadhaar_no" class="validation-msg" />
                    </div>
                  </div>
                  <div class="col-lg-6 mb-4">
                    <div class="form-group mb-0">
                      <label for="pan_no"
                        >Pan No <span class="text-danger">*</span></label
                      >
                      <div class="login-field">
                        <Field
                          name="pan_no"
                          type="text"
                          autoComplete="off"
                          id="pan_no"
                          v-model="user.pan_no"
                          class="form-control"
                          :rules="'required:PAN card,true|PAN'"
                          :validateOnInput="true"
                          :placeholder="'PAN No'"
                          autocapitalize="false"
                        />
                      </div>
                      <ErrorMessage name="pan_no" class="validation-msg" />
                    </div>
                  </div>
                  <div class="col-lg-6 mb-4">
                    <label
                      >Upload Aadhaar Card
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="wb-browse-file"
                      style="cursor: pointer"
                      @click="selectAadhaarFile($event)"
                    >
                      <Field
                        name="upload_aadhaar_file"
                        rules="required:aadhaar,true"
                        v-model="aadhaar_name"
                        type="text"
                        autoComplete="off"
                        placeholder="Browse File"
                        class="form-control"
                        :disabled="disabled == 1"
                      />
                      <Field
                        id="selectAadhaarFile"
                        name="upload_file"
                        accept=".jpeg,.jpg,.png,.webp"
                        style="display: none"
                        type="file"
                        placeholder="Browse File"
                        @change="onAadhaarDocumentChange($event)"
                        class="form-control"
                      />
                      <a
                        style="cursor: pointer"
                        @click="selectAadhaarFile($event)"
                        ><img src="/static/img/browse-icon.svg" alt=""
                      /></a>
                      <ErrorMessage
                        name="upload_aadhaar_file"
                        class="validation-msg"
                      />
                    </div>
                    <small
                      class="text-danger"
                      style="cursor: pointer !important"
                      v-if="has_aadhaar_img"
                      @click="downloadPdf(this.aadhaar_path, 'aadhaar_card')"
                      >Download</small
                    >
                  </div>
                  <div class="col-lg-6 mb-4">
                    <label
                      >Upload PAN Card
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="wb-browse-file"
                      style="cursor: pointer"
                      @click="selectPanFile($event)"
                    >
                      <Field
                        name="upload_pan_file"
                        v-model="pan_name"
                        type="text"
                        autoComplete="off"
                        placeholder="Browse File"
                        rules="required:PAN,true"
                        class="form-control"
                        :disabled="disabled == 1"
                      />
                      <Field
                        id="selectPanFile"
                        name="upload_file"
                        accept=".jpeg,.jpg,.png,.webp"
                        style="display: none"
                        type="file"
                        placeholder="Browse File"
                        @change="onPanDocumentChange($event)"
                        class="form-control"
                      />
                      <a style="cursor: pointer" @click="selectPanFile($event)"
                        ><img src="/static/img/browse-icon.svg" alt=""
                      /></a>
                      <ErrorMessage
                        name="upload_pan_file"
                        class="validation-msg"
                      />
                    </div>
                    <small
                      class="text-danger"
                      style="cursor: pointer !important"
                      v-if="has_pan_img"
                      @click="downloadPdf(this.pan_path, 'pan_card')"
                      >Download</small
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 text-right" v-if="this.id">
                    <a
                      href="javascript:void(0)"
                      @click="openChangePassModal()"
                      class="change-pass"
                      >Change Password</a
                    >
                  </div>

                  <div
                    class="col-lg-12 text-right pt-3"
                    v-if="
                      user_type == 'SUPER_ADMIN' ||
                      user_type == 'BRANCH_MANAGER' ||
                      user_type == 'PARTNER'
                    "
                  >
                    <a
                      href="javascript:void(0)"
                      @click="onAddBranchAndRole()"
                      class="btn btn-brand-01 wb-save-btn"
                      >Add Branch / Role
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card rounded-5 pt-2"
              v-if="
                user_type == 'SUPER_ADMIN' ||
                user_type == 'BRANCH_MANAGER' ||
                user_type == 'PARTNER'
              "
            >
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-primary mg-b-0">
                    <thead>
                      <tr>
                        <th>Branch Name</th>
                        <th>Role Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="accesses.length == 0">
                        <td colspan="3" class="text-center pt-5 pb-5">
                          Sorry, No Branch and role selected.
                        </td>
                      </tr>
                      <tr v-else v-for="(item, i) in accesses" :key="i">
                        <td>{{ item.branch_name }}</td>
                        <td>{{ item.role }}</td>
                        <td>
                          <a
                            title="View/Edit"
                            class="mx-2"
                            style="cursor: pointer"
                            @click="onEditBranchAndRole(i, item)"
                            ><img src="/static/img/eye-icon.svg" alt="" />
                          </a>
                          <a
                            title="Delete"
                            class="mx-2"
                            style="cursor: pointer"
                            @click="removeRoleAndBranch(i)"
                            ><img src="/static/img/delete-icon.svg" alt="" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-3">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
                  @click="$router.go(-1)"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  id="save-btn"
                  class="btn btn-brand-01 wb-save-btn"
                  v-if="showButton"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>

      <div class="row">
        <div class="col-lg-12 mb-2"></div>
      </div>
    </div>
    <div
      class="modal fade wb-modal-wrapper"
      id="changePass"
      tabindex="-1"
      role="dialog"
      aria-labelledby="changePass"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a
            class="close mr-3 mt-3 text-right"
            @click="closeModal()"
            aria-label="Close"
          >
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span>Change Password </span>
            </h5>
          </div>
          <Form
            @submit="updatePassword"
            class="columns column is-multiline is-12"
            ref="frmChangePassword"
          >
            <div class="modal-body form-style pb-0 px-lg-5">
              <div class="signin-form">
                <div class="form-group">
                  <div class="login-field">
                    <label for="Password"
                      >Password<span class="text-danger">*</span></label
                    >
                    <Field
                      name="password"
                      type="password"
                      id="password"
                      v-model="changePassword.password"
                      class="form-control"
                      v-on:keydown.space="$event.preventDefault()"
                      rules="required:password|minLength:6|password"
                      :validateOnInput="true"
                      placeholder="Password"
                      autocapitalize="false"
                    />
                    <i id="button" alt="" class="show-icon fa fa-eye-slash"></i>
                  </div>
                  <ErrorMessage name="password" class="validation-msg" />
                </div>

                <div class="form-group">
                  <div class="login-field">
                    <label for="conf_password"
                      >Confirm Password<span class="text-danger">*</span></label
                    >
                    <Field
                      name="confirm_password"
                      type="password"
                      id="conf_password"
                      v-model="changePassword.confirm_password"
                      v-on:keydown.space="$event.preventDefault()"
                      rules="required:confirm password|confirmed:@password"
                      class="form-control"
                      :validateOnInput="true"
                      placeholder="Confirm Password"
                      autocapitalize="false"
                    />
                    <i id="button" alt="" class="show-icon fa fa-eye-slash"></i>
                  </div>
                  <ErrorMessage
                    name="confirm_password"
                    class="validation-msg"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center mb-3">
              <button
                id="cancel-btn"
                @click="closeModal()"
                type="button"
                class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-brand-01 wb-save-btn"
                id="update-password"
              >
                Save
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <div
      class="modal fade wb-modal-wrapper"
      id="onAddBranchRole"
      tabindex="-1"
      role="dialog"
      aria-labelledby="onAddBranchRole"
      aria-hidden="true"
      style="pointer-events: none"
    >
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
          <a
            class="close mr-3 mt-3 text-right"
            @click="onCloseBranchRoleModal()"
            aria-label="Close"
          >
            <img src="/static/img/close-icon.svg" width="20" />
          </a>
          <div class="modal-header justify-content-center">
            <h5 class="modal-title font-22">
              <span v-if="item_index != null">Edit Branch / Role</span>
              <span v-else>Add Branch / Role</span>
            </h5>
          </div>
          <Form
            @submit="onSaveBranchAndRole"
            class="columns column is-multiline is-12"
            ref="resetBranchAndRole"
          >
            <div class="modal-body form-style pb-0 px-lg-5">
              <div class="row">
                <div class="col-lg-12 mb-4">
                  <label> Branch <span class="text-danger">*</span> </label>
                  <Field
                    v-slot="{ field }"
                    name="branch_id"
                    rules="required:branch,true"
                    :validateOnInput="true"
                    v-model="selectedBranch"
                  >
                    <Multiselect
                      v-bind="field"
                      ref="branch_id_multiselect"
                      trackBy="name"
                      rules="required"
                      @select="getUserRoleBasedOnType('INNER_BRANCH')"
                      label="name"
                      valueProp="branch_id"
                      placeholder="Select Branch"
                      class="form-control"
                      v-model="selectedBranch"
                      :options="branchList"
                      :searchable="true"
                    />
                  </Field>
                  <ErrorMessage name="branch_id" class="validation-msg" />
                </div>
                <div class="col-lg-12 mb-4">
                  <label> Role <span class="text-danger">*</span> </label>
                  <Field
                    v-slot="{ field }"
                    name="role_id"
                    rules="required:role,true"
                    :validateOnInput="true"
                    v-model="selectedRole"
                  >
                    <Multiselect
                      v-bind="field"
                      ref="role_id_multiselect"
                      trackBy="name"
                      rules="required"
                      label="role_name"
                      valueProp="id"
                      placeholder="Select Role"
                      class="form-control"
                      v-model="selectedRole"
                      :options="roles"
                      :searchable="true"
                    />
                  </Field>
                  <ErrorMessage name="role_id" class="validation-msg" />
                </div>
              </div>
            </div>
            <div class="modal-footer border-0 justify-content-center mb-3">
              <button
                id="cancel-btn"
                @click="onCloseBranchRoleModal()"
                type="button"
                class="btn btn-brand-01 wb-cancel-btn wb_disable_btn mr-lg-3 mr-2"
              >
                Cancel
              </button>
              <button type="submit" class="btn btn-brand-01 wb-save-btn">
                Save
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
<script>
import Multiselect from "@vueform/multiselect";
import $ from "jquery";
import "@vueform/multiselect/themes/default.css";
import { Form, Field, ErrorMessage } from "vee-validate";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
export default {
  name: "AddEdit",
  components: {
    Multiselect,
    Form,
    Field,
    ErrorMessage,
    VueDatePicker,
  },
  data() {
    return {
      id: this.$route.params.id,
      iti_1: undefined,
      user: {
        name: "",
        email: "",
        dial_code: "",
        country_code: "",
        doc_type: "AADHAAR_CARD",
        phone_number: "",
        role_id: "",
        doc_no: "",
        password: "",
        confirm_password: "",
        status: "ACTIVE",
        branch_ids: [],
        joining_date: null,
        branch_id: "",
      },
      accesses: [],
      userType: [],
      taskforces: [],
      passwordFieldType: "password",
      image: "/static/img/password.svg",
      selected_role: null,
      changePassword: {
        current_password: "",
        password: "",
        confirm_password: "",
      },
      roles: [],
      role_ids: [],
      user_type: null,
      changePasswordFlag: false,
      showButton: true,
      my_user_id: "",
      selectedBranch: null,
      selectedRole: null,
      item_index: null,
      aadhaar_name: "",
      aadhaar_img: "",
      pan_name: "",
      pan_img: "",
      disabled: 1,
      has_aadhaar_img: false,
      has_pan_img: false,
      pan_path: "",
      aadhaar_path: "",
    };
  },

  computed: {
    branchList() {
      let list = this.$storeService.getters.getBranches;
      console.log("branch list", list);
      return list;
    },
    hasFullAccess() {
      return this.$storeService.getters.getHasFullAccess;
    },
  },
  mounted() {
    $(document).ready(function () {
      $(".show-icon").on("click", function (event) {
        event.preventDefault();
        console.log("type : ", $(this).parent().find("input").attr("type"));
        var input = $(this).parent().find("input");
        if (input.attr("type") == "text") {
          input.attr("type", "password");
          $(this)
            .parent()
            .find("i")
            .removeClass("fa-eye")
            .addClass("fa-eye-slash");
        } else if (input.attr("type") == "password") {
          input.attr("type", "text");
          $(this)
            .parent()
            .find("i")
            .removeClass("fa-eye-slash")
            .addClass("fa-eye");
        }
      });
    });

    this.getUserType();
    this.onGetTaskForces();
  },
  methods: {
    onClearForm(formRefName) {
      this.$refs[formRefName].resetForm();
    },
    openChangePassModal() {
      window.$("#changePass").modal("show");
    },

    closeModal() {
      window.$("#changePass").modal("hide");
      this.onClearForm("frmChangePassword");
    },
    onAddBranchAndRole() {
      this.onClearForm("resetBranchAndRole");
      window.$("#onAddBranchRole").modal("show");
    },
    onEditBranchAndRole(index, item) {
      this.selectedBranch = item?.branch_id;
      this.getRoleList(this.selectedBranch, item?.role_id);
      this.item_index = index;
      console.log("indexof the selectedBranch is :", this.selectedBranch);
      console.log("indexof the selectedRole is :", this.selectedRole);
      window.$("#onAddBranchRole").modal("show");
    },
    onCloseBranchRoleModal() {
      this.item_index = null;
      this.roles = [];
      this.selectedRole = "";
      this.selectedBranch = "";
      window.$("#onAddBranchRole").modal("hide");
    },
    onSaveBranchAndRole() {
      console.log(
        "the value of the selected feilds are :",
        this.selectedBranch,
        this.selectedRole
      );
      var obj = {
        branch_id: this.selectedBranch,
        role_id: this.selectedRole,
      };
      for (var i = 0; i < this.branchList.length; i++) {
        if (this.branchList[i].branch_id == obj.branch_id) {
          obj.branch_name = this.branchList[i].name;
        }
      }
      for (var j = 0; j < this.roles.length; j++) {
        if (this.roles[j].id == obj.role_id) {
          obj.role = this.roles[j].role_name;
        }
      }
      if (this.item_index != null) {
        console.log(
          "value based on index befor is : ",
          this.accesses[this.item_index]
        );
        this.accesses[this.item_index] = obj;
        console.log(
          "value based on index after is : ",
          this.accesses[this.item_index]
        );
      } else {
        this.accesses.push(obj);
      }
      console.log("the value of the accesses are :", this.accesses);
      this.onCloseBranchRoleModal();
      console.log(
        "the value of the feilds are :",
        this.selectedRole,
        this.selectedBranch
      );
    },
    removeRoleAndBranch(i) {
      this.accesses.splice(i, 1);
    },

    onSwitchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/static/img/password.svg"
          ? "/static/img/password.svg"
          : "/static/img/password.svg";
    },
    getUserInfo() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "admin-user/" + this.id,
        })
        .then((res) => {
          this.user = res.info;
          this.accesses = res?.info?.roles;
          this.user_type = res?.info?.user_type;

          if (res?.info?.aadhaar_card_img != null) {
            this.has_aadhaar_img = true;
            this.aadhaar_name = this.basename(res?.info?.aadhaar_card_img);
            this.aadhaar_name = this.aadhaar_name.split("?")[0];
            this.aadhaar_path = decodeURI(res?.info?.aadhaar_card_img);
          }

          if (res?.info?.pan_card_img != null) {
            this.has_pan_img = true;
            this.pan_name = this.basename(res?.info?.pan_card_img);
            this.pan_name = this.pan_name.split("?")[0];
            this.pan_path = decodeURIComponent(res?.info?.pan_card_img);
          }

          console.log("console log from getUserInfo  outer: ", this.my_user_id);
          if (this.my_user_id == res.info.id) {
            console.log(
              "console log from getUserInfo  inner: ",
              this.my_user_id
            );
            this.showButton = false;
          }
          if (res.info.branches) {
            for (var i = 0; i < res.info.branches.length; i++) {
              if (
                this.user_type == "SUPER_ADMIN" ||
                this.user_type == "BRANCH_MANAGER" ||
                this.user_type == "PARTNER" ||
                this.user_type == "BRANCH_STAFF" ||
                this.user_type == "ENQUIRY_USER" ||
                this.user_type == "DOCUMENT_VERIFY_USER"
              ) {
                this.getRoleList(res.info.branches[i].branch_id);
              }
              console.log("branch_id " + res.info.branches[i].branch_id);
              this.user.branch_id = res.info.branches[i].branch_id;
              if (res.info.branches[i].role_id) {
                console.log("role_id " + res.info.branches[i].role_id);
                this.user.role_id = res.info.branches[i].role_id;
              }
            }
          }
          console.log("The user type is :", this.user_type);
          if (res.info.roles) {
            this.user.role_id = res.info.role.id;
          }
        })
        .catch(() => {});
    },
    getUserRoleBasedOnType(type) {
      if (type == "INNER_BRANCH") {
        if (
          this.user_type != null &&
          (this.user_type == "SUPER_ADMIN" ||
            this.user_type == "BRANCH_MANAGER" ||
            this.user_type == "PARTNER")
        ) {
          this.selectedRole = "";
          if (this.selectedBranch) {
            this.getRoleList(this.selectedBranch);
          }
        }
      }
      if (type == "OUTER_BRANCH") {
        if (
          (this.user_type != null && this.user_type == "BRANCH_STAFF") ||
          this.user_type == "ENQUIRY_USER" ||
          this.user_type == "DOCUMENT_VERIFY_USER"
        ) {
          this.getRoleList(this.user.branch_id);
        }
      }
    },
    getRoleList(branch_id, role_id) {
      this.roles = [];
      this.$api
        .webRequest({
          _method: "GET",
          _action: "role/list",
          _body: { user_type: this.user_type, branch_id: branch_id },
        })
        .then((res) => {
          this.roles = res.list;
          if (role_id) {
            this.selectedRole = role_id;
          }
        })
        .catch(() => {});
    },
    getUserType() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/user-types",
        })
        .then((res) => {
          this.userType = res.list;
          if (this.id) {
            this.my_user_id = localStorage.getItem("my_user_id");
            this.getUserInfo();
          }
        })
        .catch(() => {});
    },

    save() {
      console.log("the value of the accesses are :", this.accesses);
      var date = "";
      if (this.user.joining_date) {
        date = moment(String(this.user.joining_date)).format("Y-MM-DD");
      }
      var obj = {
        name: this.user.name,
        email: this.user.email,
        mobile_number: this.user.mobile_number,
        user_type: this.user_type,
        password: this.user.password,
        joining_date: date,
        salary: this.user.salary,
        role_id: this.user.role_id,
        branch_id: this.user.branch_id,
        accesses: this.accesses,
        status: this.user.status,
        level: this.user.level,
        task_force_level: this.user.task_force_level,
        aadhaar_no: this.user.aadhaar_no,
        pan_no: this.user.pan_no,
      };
      this.user.user_type = this.user_type;
      if (obj) {
        if (
          this.user_type == "SUPER_ADMIN" ||
          this.user_type == "BRANCH_MANAGER" ||
          this.user_type == "PARTNER"
        ) {
          obj = {
            name: this.user.name,
            email: this.user.email,
            mobile_number: this.user.mobile_number,
            user_type: this.user_type,
            password: this.user.password,
            joining_date: date,
            salary: this.user.salary,
            accesses: this.accesses,
            status: this.user.status,
            aadhaar_no: this.user.aadhaar_no,
            pan_no: this.user.pan_no,
          };
          console.log("the value of the user is : ", obj);
        }

        if (
          this.user_type == "BRANCH_STAFF" ||
          this.user_type == "ENQUIRY_USER" ||
          this.user_type == "DOCUMENT_VERIFY_USER"
        ) {
          var dummyString = JSON.stringify(
            obj,
            [
              "name",
              "email",
              "mobile_number",
              "user_type",
              "password",
              "joining_date",
              "salary",
              "role_id",
              "branch_id",
              "status",
              "aadhaar_no",
              "pan_no",
            ],
            0
          );
          obj = JSON.parse(dummyString);
          console.log("the value of the user is : ", obj);
        }

        if (
          this.user_type == "COLLECTION_MANAGER" ||
          this.user_type == "VERIFICATION_MANAGER"
        ) {
          var dummyString1 = JSON.stringify(
            obj,
            [
              "name",
              "email",
              "mobile_number",
              "user_type",
              "password",
              "joining_date",
              "salary",
              "branch_id",
              "status",
              "level",
              "task_force_level",
              "aadhaar_no",
              "pan_no",
            ],
            0
          );
          obj = JSON.parse(dummyString1);
          console.log("the value of the user is : ", obj);
        }
      }

      var method = "POST";
      var action = "/admin-user";
      if (this.id) {
        method = "PUT";
        action = "admin-user/" + this.id;
      }
      this.$api
        .webRequest({
          _method: method,
          _action: action,
          _body: obj,
          _buttonId: "save-btn",
          _hide_loader: true,
        })
        .then((res) => {
          this.onClearForm("addEditUser");

          var temp = [];
          if (this.aadhaar_img) {
            temp.push({
              type: "AADHAAR",
              image: this.aadhaar_img,
              uuid: res?.id,
            });
          }
          if (this.pan_img) {
            temp.push({ type: "PAN", image: this.pan_img, uuid: res?.id });
          }
          setTimeout(() => {
            temp.forEach((obj) => {
              this.onUploadUserDocument(obj.image, obj.uuid, obj.type);
            });
          }, 1000);

          this.$toast.success(res.message, { position: "top-right" });
          this.$router.go(-1);
          // this.aadhaar_img = "";
          // this.pan_img = "";
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    updatePassword() {
      this.$api
        .webRequest({
          _method: "POST",
          _action: "admin-user/" + this.id + "/reset-password",
          _body: this.changePassword,
          _buttonId: "save-btn",
          _hide_loader: true,
        })
        .then((res) => {
          // window.$("#changePass").modal("hide");
          this.$toast.success(res.message, { position: "top-right" });
          this.closeModal();
        })
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    onGetTaskForces() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "select/task-forces",
        })
        .then((res) => {
          this.taskforces = res.list;
        })
        .catch(() => {});
    },
    selectAadhaarFile(e) {
      e.stopImmediatePropagation();
      window.$("#selectAadhaarFile").click();
    },
    onAadhaarDocumentChange(e) {
      let files = e.target.files;
      if (files.length > 0) {
        this.aadhaar_name = files[0].name;
        this.aadhaar_img = files[0];
      } else {
        this.aadhaar_name = null;
        this.aadhaar_img = null;
      }
    },
    selectPanFile(e) {
      e.stopImmediatePropagation();
      window.$("#selectPanFile").click();
    },
    onPanDocumentChange(e) {
      let files = e.target.files;
      if (files.length > 0) {
        this.pan_name = files[0].name;
        this.pan_img = files[0];
      } else {
        this.pan_name = null;
        this.pan_img = null;
      }
    },
    afterValueChange() {
      this.user.doc_no = "";
      this.file = "";
    },
    onUploadUserDocument(file, uuid, type) {
      console.log(
        "File >>>>>> " + file,
        "uuid >>>>>> " + uuid,
        "type >>>>>> ",
        type
      );
      this.$api
        .uploadImageAPI({
          _action: "admin-user/" + uuid + "/upload/document",
          _key: "image",
          _file: file,
          _body: { type: type },
        })
        .then(() => {})
        .catch((e) => {
          this.$toast.error(e.message, { position: "top-right" });
        });
    },
    downloadPdf(url, filename) {
      console.log(url, filename);
      var anchorElement = document.createElement("a");
      anchorElement.href = url;
      anchorElement.download = filename;
      anchorElement.target = "_blank";
      document.body.appendChild(anchorElement);
      console.log(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
    },
    basename(path) {
      return path.split("/").reverse()[0];
    },
  },
};
</script>

<style>
.multiselect-tags-search {
  top: -1px !important;
}
</style>
